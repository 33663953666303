import React from "react";
import "./css/index.css";
import PosType from "../../components/PosType/PosType";
import Section02 from "./components/Section02/Section02";
import Section03 from "./components/Section03/Section03";
import Section04 from "./components/Section04/Section04";
import Section05 from "./components/Section05/Section05";
import Section06 from "./components/Section06/Section06";
import { UserAgentType } from "../../interface/common/common.inferface";
import useIntersectionObserver from "../../hooks/UseIntersectionObserver";
import FooterContainer from "../../components/Footer/container/FooterContainer";

type Props = {
  userAgent: UserAgentType;
};
const AlertTalk = ({ userAgent }: Props) => {
    const ref = useIntersectionObserver(0.3);
  return (
    <main className="alert-talk-page">
      <PosType
        colorText="알림톡 마케팅"
        mainText="모바일 쇼핑 시대,
이제는 알림톡 마케팅이다"
        subText="회원가입 없이 간편하게
알림톡으로 마케팅하고 제품 구매까지
한번에 진행하는 알림톡 마케팅을 만나보세요"
        src={`/assets/alerttalk/${userAgent}/main.png`}
        srcSet={`
          /assets/alerttalk/${userAgent}/main@2x.png 2x, 
        /assets/alerttalk/${userAgent}/main@3x.png 3x"`}
      />
      <Section02 userAgent={userAgent} />
      <Section03  />
      <Section04 userAgent={userAgent} />
      <Section05 userAgent={userAgent} />
      <Section06 userAgent={userAgent} />
      <section ref={ref} className="alert-section07 hidden-section">
        <div className="text">
          {userAgent !== "mb"
            ? `판매부터 마케팅까지
SKPOS 알림톡 마케팅과 함께하세요`
            : `판매부터 마케팅까지
SKPOS 알림톡
마케팅과 함께하세요`}
        </div>
        <img
          className="bg-img"
          src={`/assets/alerttalk/section07/${userAgent}/section07.png`}
          srcSet={`
                /assets/alerttalk/section07/${userAgent}/section07@2x.png 2x,
                /assets/alerttalk/section07/${userAgent}/section07@3x.png 3x
                `}
          alt=""
        />
      </section>
      <FooterContainer />
    </main>
  );
};

export default AlertTalk;
