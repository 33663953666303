import React from 'react'
import "./css/index.css";

type Props = {
  MenuClicked: (link:string) => void;
}

const HoverInquiry = ({ MenuClicked }: Props) => {
  return (
    <div className='hover-inquiry'>
      {inquiryArr.map(({ link, title }, idx) => (
        <button
          onClick={() => MenuClicked( link)}
          key={idx}
          className='menu-text'
        >
          {title}
        </button>
      ))}
    </div>
  )
}

export default HoverInquiry;

const inquiryArr = [
  {
    title: "상담문의",
    link: "/user/loading",
  },
  {
    title: "사업 제휴 문의",
    link: "/counsel/business",
  },
];