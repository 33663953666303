import React from "react";
import "./css/index.css";
import { UserAgentType } from "../../interface/common/common.inferface";
import PcTopTrendBox from "../PcTopTrendBox/PcTopTrendBox";
import PcMiddleTrendBox from "../PcMiddleTrendBox/PcMiddleTrendBox";
import PcBottomTrendBox from "../PcBottomTrendBox/PcBottomTrendBox";
type Props = {
  userAgent: UserAgentType;
};
const PCTrendBox = ({ userAgent }: Props) => {
  return (
    <div className="pc-trend-box">
      <PcTopTrendBox userAgent={userAgent} />
      <PcMiddleTrendBox userAgent={userAgent} />
      <PcBottomTrendBox userAgent={userAgent}/>
    </div>
  );
};

export default PCTrendBox;
