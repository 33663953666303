import React, { forwardRef } from 'react';
import "./css/index.css";
import { UserAgentType } from '../../../interface/common/common.inferface'
type Props = {
  userAgent: UserAgentType;
  currentIndex:number;
  images:string[]
};
const MainSection9 = forwardRef<HTMLDivElement, Props>(
  ({ userAgent, currentIndex, images }, ref) => {
    return (
      <section className="main-section9 hidden-section" ref={ref}>
        <div className="wrapper">
          <div className="text-wrapper">
            <p className="title">시가 상품 판매</p>
            <p className="sub-text">
              {userAgent !== "mb"
                ? `매일매일 가격이 변하는 시가상품을 판매 할 수 있어요`
                : `매일매일 가격이 변하는
시가상품을 판매 할 수 있어요`}
            </p>
          </div>
          <div className="img-wrapper">
            <div className="img-box">
              {images.map((image, idx) => (
                <img
                  key={idx}
                  src={`/assets/mainsection9/${userAgent}/${image}.png`}
                  srcSet={`
                /assets/mainsection9/${userAgent}/${image}@2x.png 2x,
                /assets/mainsection9/${userAgent}/${image}@3x.png 3x,
                `}
                  alt={`Slide ${idx + 1}`}
                  className={`slide ${currentIndex === idx ? "active" : ""}`}
                />
              ))}
            </div>
            <p className="description">{`시가 상품 등록 후
가격만 입력 하면 돼요`}</p>
          </div>
        </div>
      </section>
    );
  }
);

export default MainSection9