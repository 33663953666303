import React from "react";
import "./css/index.css";
import { UserAgentType } from "../../../interface/common/common.inferface";
import StoreCard from "../../StoreCard/StoreCard";
import InquiryButtonContainer from "../../InquiryButton/container/InquiryButtonContainer";
import useIntersectionObserver from "../../../hooks/UseIntersectionObserver";
import PcAlertTalk from "../../AlertTalk/PcAlertTalk";
import MbAlertTalk from "../../AlertTalk/MbAlertTalk";

type Props = {
  userAgent: UserAgentType;
  isView?: boolean;
};
const MainSection12 = ({ userAgent, isView }: Props) => {
  const targetRef = useIntersectionObserver(0.2);
  const targetRef4 = useIntersectionObserver(0.1);
  const targetRef5 = useIntersectionObserver(0.1);
  const targetRef6 = useIntersectionObserver(0);
  const storeArr = [
    {
      content: "매장 홍보가 필요하다",
    },
    {
      content: "디자인 및 광고 관리할 인력이 없다",
    },
    {
      content:
        userAgent !== "mb"
          ? "필터링된 고객에게 타겟 발송을 하고 싶다"
          : `필터링된 고객에게
타겟 발송을 하고 싶다`,
    },
    {
      content:
        userAgent !== "mb"
          ? "쿠폰으로 고객들의 재방문을 유도하고 싶다"
          : `쿠폰으로 고객들의 재방문을 
유도하고 싶다`,
    },
    {
      content: "앱으로 주문과 결제까지 받고 싶다",
    },
    {
      content:
        userAgent !== "mb"
          ? "매출로 이어지는 직접적인 마케팅이 필요하다"
          : `매출로 이어지는 직접적인 
마케팅이 필요하다`,
    },
  ];
  return (
    <section className="main-section12 ">
      <div className="wrapper">
        {!isView && (
          <div className="top-box">
            <div className="text-wrapper hidden-section" ref={targetRef}>
              <p className="text">앱 설치 하지 마세요</p>
              <p className="sub-text">
                제품 홍보 부터 구매, 결제까지 이 모든걸 알림톡만 발송하면 끝
              </p>
            </div>
            {userAgent !== "mb" ? (
              <PcAlertTalk userAgent={userAgent} />
            ) : (
              <div className="mobile-box">
                {["알림톡 발송", "행사 전단", "구매 | 결제"].map(
                  (text, idx) => (
                    <MbAlertTalk
                      key={idx}
                      text={text}
                      idx={idx}
                      userAgent={userAgent}
                    />
                  )
                )}
              </div>
            )}
          </div>
        )}
        <div
          className="bottomo-box"
          style={isView ? { margin: "unset" } : undefined}
        >
          <div className="text-wrapper hidden-section" ref={targetRef4}>
            <p className="text">알림톡 발송 비용 10원!</p>
            <p className="sub-text">
              {userAgent !== "mb"
                ? `우리동네 고객에게 직접 마케팅 하세요`
                : `우리동네 고객에게 직접
마케팅 하세요`}
            </p>
          </div>
          <div className="img-box hidden-section" ref={targetRef5}>
            <img
              src={`/assets/mainsection12/${userAgent}/img4.png`}
              srcSet={`
                      /assets/mainsection12/${userAgent}/img4@2x.png 2x,
                      /assets/mainsection12/${userAgent}/img4@3x.png 3x,
                    `}
              alt="img-4"
              className="img"
            />
          </div>
          <div className="card-wrapper hidden-section" ref={targetRef6}>
            <p className="card-title">이런 매장에 이용하시면 좋아요</p>
            <div className="card-lists">
              {storeArr.map(({ content }, idx) => (
                <StoreCard key={idx} content={content} />
              ))}
            </div>
            <div className="button-box">
              <InquiryButtonContainer />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection12;
