import React, { useEffect, useRef, useState } from "react";
import "./css/index.css";
import { UserAgentType } from "../../interface/common/common.inferface";
import ContentOddCard from "../ContentOddCard/ContentOddCard";
import ContentCard from "../ContentCard/ContentCard";
import useIntersectionObserver from "../../hooks/UseIntersectionObserver";

type Props = {
  content: {
    sub: string;
    title: string;
    img: string;
    bgColor?: string;
  }[];
  userAgent: UserAgentType;
};

const SliderContent = ({ content, userAgent }: Props) => {
  const targetRef = useIntersectionObserver(0.3);
  const animationRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const animationElement = animationRef.current;
    if (!animationElement) return;

    let animationFrameId: number;
    let startTime: number | null = null;
    let baseSpeed = 7;
    let hoverSpeed = 12;
    let isHover = false;
    let currentSpeed = baseSpeed;

    const handleMouseEnter = () => {
      isHover = true;
    };

    const handleMouseLeave = () => {
      isHover = false;
    };

    animationElement.addEventListener("mouseenter", handleMouseEnter);
    animationElement.addEventListener("mouseleave", handleMouseLeave);

    const lerp = (start: number, end: number, t: number) => {
      return start + (end - start) * t;
    };

    const animate = (time: number) => {
      if (!startTime) startTime = time;
      const elapsed = time - startTime;
      const width = animationElement.scrollWidth;
      const cardWidth = width >= 2015 ? 439 : width >= 1920 ? 320 : 263;
      if (cardWidth <= 263) {
        baseSpeed = 10;
      } else {
        baseSpeed = 6;
      }
      const targetSpeed = isHover ? hoverSpeed : baseSpeed;
      currentSpeed = lerp(currentSpeed, targetSpeed, 0.01);

      const transformValue = (elapsed / currentSpeed) % width;

      animationElement.style.transform = `translateX(-${transformValue}px)`;

      if (transformValue >= cardWidth) {
        const firstChild = animationElement.firstElementChild;
        if (firstChild) {
          animationElement.appendChild(firstChild);
          animationElement.style.transform = `translateX(0)`;
          startTime = null;
        }
      }

      animationFrameId = requestAnimationFrame(animate);
    };

    animationFrameId = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(animationFrameId);
      animationElement.removeEventListener("mouseenter", handleMouseEnter);
      animationElement.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  return (
    <div className="slider-content ">
      <div className="slider-wrapper" ref={targetRef}>
        <div className={`slider-track `} ref={animationRef}>
          {content.map(({ sub, title, img, bgColor }, idx) => {
            const isEven = idx % 2 === 0;
            return isEven ? (
              <ContentOddCard
                userAgent={userAgent}
                img={img}
                sub={sub}
                key={idx}
                title={title}
              />
            ) : (
              <ContentCard
                userAgent={userAgent}
                img={img}
                sub={sub}
                title={title}
                bgColor={bgColor}
                key={idx}
              />
            );
          })}
        </div>
        {/* <div className={`slider-track clone`}>
          {content.map(({ sub, title, img, bgColor }, idx) => {
            const isEven = idx % 2 === 0;
            return isEven ? (
              <ContentOddCard
                userAgent={userAgent}
                img={img}
                sub={sub}
                title={title}
                key={idx}
              />
            ) : (
              <ContentCard
                userAgent={userAgent}
                img={img}
                sub={sub}
                title={title}
                bgColor={bgColor}
                key={idx}
              />
            );
          })}
        </div> */}
      </div>
      <div className="company-img-wrapper">
        <div className="company-img original">
          {[...Array(14)].map((_, index) => (
            <img
              key={index}
              src={`/assets/mainsection2/${userAgent}/company${index + 1}.png`}
              srcSet={`/assets/mainsection2/${userAgent}/company${
                index + 1
              }@2x.png 2x,
                /assets/mainsection2/${userAgent}/company${index + 1}@3x.png 3x,
                `}
              alt={`company${index + 1}`}
            />
          ))}
        </div>
        <div className="company-img clone">
          {[...Array(14)].map((_, index) => (
            <img
              key={index}
              src={`/assets/mainsection2/${userAgent}/company${index + 1}.png`}
              srcSet={`/assets/mainsection2/${userAgent}/company${
                index + 1
              }@2x.png 2x,
                /assets/mainsection2/${userAgent}/company${index + 1}@3x.png 3x,
                `}
              alt={`company${index + 1}`}
            />
          ))}
        </div>
      </div>
      <div className="company-text-wrapper ">
        <div className="text text-original">{`아진건어물백화점  |  공원매점  |  내광슈퍼  |  이땅에축산물판매장   |  해청건어물백화점  |  경산약국  |  밥친구반찬  |  지운이네할인마트  |  경보식품  |  동우슈퍼  |  구미시추모공원매점  |  케이에스인터네셔널  |  마루농업회사법인주식회사  |  연진중국식품  |  팬시조아  |  후평프라자  |  더조은아이스크림  |  (주)남성  |  풍모슈퍼  |  장가요몰  |  두일세라믹  |  가득상사  |  한세유통  |  탑패밀리마트  |  SK종합상사  |  고리(GL)  |  명성유통일삼오마트   |  우리종합인마트  |  공단슈퍼  |  대성마트  |  (주)미래종합아울렛   |  모닝마트   |  왕자문구상사   |  청주한우(청우)  |  베이비파크  |  천리유통   |  세븐타이어  |  신라식품   |  영어조합법인올레바당   |  원지축산유통   |  (주)레인보우제주   |  도깨비광장  |  상미생활용품  |  명품축산육가공센터  |  크레파스문구완구   |  나성산업  |  한농  |  아이스베어  |  대박편의점  |  다미유통  |  제주수퍼킴스물류  |  미원24편의점  |  농업법인청정웰푸드  | (주)바이크런 |  모닝마트  |  동원농산  |  플러스홈마트  | 태영철물건재  |  (주)미래종합아울렛 |  현대상회  |  세경유통  |
        
설아축산유통  |  다솜유통  |  피쉬플러스(주)  |  주식회사두리유통  |  명품축산물도매센터  |  다판다철물점  | 율량할인마트  |  일품미가  |  태영농장의령칡한  |  일송마트  |  생활낚시닷컴  |  합천명품토종돼지  |  후레쉬마트  |  싱싱푸드  |  경남그릇백화점팔용  |  코오롱할인마트  |  풍성한축산  |  다판다철물점  |  원지축산  |  신정고기백화점  |  탐라낚시  |  남해마늘축산   |  상옥할인마트  |  국제유통  |  지오유통  |  대한상사  |  바이크하우스  |  정통식품  |  시골장터마트  |  다사랑할인마트  |  통영물산주식회사  |  서면하우징  |  유진아트문구  |  (주)피싱타임  |  착한구이  |  노루페인트  |  칠공구철물종합상사  |  주식회사키친앤리빙  |  광신마트  |  바른생각  |  (주)명치세상  |  모든하우징  |  (주)아텍스평택점  |  1.9만물  |  가예유통  | (유)우리도매물류센타 | 이천제일식자재 |  영풍종합유통  |  늘푸른마트  |  하프라인  | 대한일코퍼레이션 |  뉴미래건강  | 전국식자재생필품유통 |  7공구종합상사  |  일신유통센타  |  비오비애완용품전문점  |  제이엠축산  | 주)에이치에프엔에스 |  에프에스유통(주)  |  삼양유통 | `}</div>
        <div className="text text-clone">{`아진건어물백화점  |  공원매점  |  내광슈퍼  |  이땅에축산물판매장   |  해청건어물백화점  |  경산약국  |  밥친구반찬  |  지운이네할인마트  |  경보식품  |  동우슈퍼  |  구미시추모공원매점  |  케이에스인터네셔널  |  마루농업회사법인주식회사  |  연진중국식품  |  팬시조아  |  후평프라자  |  더조은아이스크림  |  (주)남성  |  풍모슈퍼  |  장가요몰  |  두일세라믹  |  가득상사  |  한세유통  |  탑패밀리마트  |  SK종합상사  |  고리(GL)  |  명성유통일삼오마트   |  우리종합인마트  |  공단슈퍼  |  대성마트  |  (주)미래종합아울렛   |  모닝마트   |  왕자문구상사   |  청주한우(청우)  |  베이비파크  |  천리유통   |  세븐타이어  |  신라식품   |  영어조합법인올레바당   |  원지축산유통   |  (주)레인보우제주   |  도깨비광장  |  상미생활용품  |  명품축산육가공센터  |  크레파스문구완구   |  나성산업  |  한농  |  아이스베어  |  대박편의점  |  다미유통  |  제주수퍼킴스물류  |  미원24편의점  |  농업법인청정웰푸드  | (주)바이크런 |  모닝마트  |  동원농산  |  플러스홈마트  | 태영철물건재  |  (주)미래종합아울렛 |  현대상회  |  세경유통  |
        
설아축산유통  |  다솜유통  |  피쉬플러스(주)  |  주식회사두리유통  |  명품축산물도매센터  |  다판다철물점  | 율량할인마트  |  일품미가  |  태영농장의령칡한  |  일송마트  |  생활낚시닷컴  |  합천명품토종돼지  |  후레쉬마트  |  싱싱푸드  |  경남그릇백화점팔용  |  코오롱할인마트  |  풍성한축산  |  다판다철물점  |  원지축산  |  신정고기백화점  |  탐라낚시  |  남해마늘축산   |  상옥할인마트  |  국제유통  |  지오유통  |  대한상사  |  바이크하우스  |  정통식품  |  시골장터마트  |  다사랑할인마트  |  통영물산주식회사  |  서면하우징  |  유진아트문구  |  (주)피싱타임  |  착한구이  |  노루페인트  |  칠공구철물종합상사  |  주식회사키친앤리빙  |  광신마트  |  바른생각  |  (주)명치세상  |  모든하우징  |  (주)아텍스평택점  |  1.9만물  |  가예유통  | (유)우리도매물류센타 | 이천제일식자재 |  영풍종합유통  |  늘푸른마트  |  하프라인  | 대한일코퍼레이션 |  뉴미래건강  | 전국식자재생필품유통 |  7공구종합상사  |  일신유통센타  |  비오비애완용품전문점  |  제이엠축산  | 주)에이치에프엔에스 |  에프에스유통(주)  |  삼양유통 | `}</div>
      </div>
    </div>
  );
};

export default SliderContent;
