import { Fragment } from "react";
import "./css/index.css"
import FooterContainer from "../../components/Footer/container/FooterContainer";
const Privacy = () => {
    return (
        <main className="privacy-page">
            <section className="privacy-wrapper">
                <div className="titl-wrapper">개인정보 처리방침</div>
                <div className="privacy-description">
                    <div className="privacy-detail">
                        (주)피플히어로는 개인정보 보호법 제30 조에 따라 정보주체(고객)의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 개인정보 처리지침을 수립하여 공개합니다.
                    </div>
                    {contentArr.map(({title,content},idx)=>(
                        <Fragment key={idx}>
                            <div className="title">{title}</div>
                            <div className="content">{content}</div>
                        </Fragment>
                    ))}
                </div>
            </section>
            <FooterContainer/>
        </main>
    )
};
const contentArr=[
    {
        title:'제 1조 [개인정보의 처리목적]',
        content:`(주)피플히어로가 개인정보를 수집하는 목적은 이용자의 신분과 서비스 이용의사를 확인하여 서비스를 제공하기 위함입니다. 회원가입 시 서비스의 본질적 기능을 수행하기 위해 최소한의 정보만을 수집하고 있으며,
(주)피플히어로가 제공하는 서비스 이용에 따른 금액 결제, 물품 또는 서비스의 공급, 배송 등의 용도로 개인정보를 처리하고 있습니다.`
    },
    {
        title: '제 2조 [개인정보의 처리 및 보유기간]',
        content:`① (주)피플히어로는 정보주체로부터 개인정보를 수집할때 동의 받은 개인정보 이용기간 동안 보유 및 이용하며, 또는 법령에 명시된 개인정보 보유기간에 따라 개인정보를 처리 및 보존 합니다. 
② 구체적인 개인정보 처리 및 보유 기간은 다음 각호와 같습니다.
  1. 고객 가입 및 관리: 서비스 이용계약 또는 회원가입 해지시까지 관리합니다. (다만 채권 및 채무관계 잔존시에는 해당 채권 및 채무관계 정산시까지 관리합니다.)
  2. 관계 법률상의 목적에 의한 보유기간은 약 3년~10년으로 아래 표와 같습니다.
                              (전자상거래 등에서의 소비자보호에 관한 법률)
  3.부가가치세법 : 장부,세금계산서,수입세금계산서,영수증 등 : 5년
			      (부가가치세의 과세표준과 세액의 신고자료 등)
  4.전자금융거래법 : 전자금융거래기록 확인 : 5년
			      (전자금융거래에 관한 기록,상대방에 관한 정보 등)
  5.특정 금융거래정보의 보고 및 이용 등에 관한 법률 : 고객확인 의무 : 5년
			      (고객확인 정보 등)`
    },
    {
        title: '제 3조 [개인정보의 제3자 제공]',
        content: `(주)피플히어로는 정보주체(고객)의 개인정보를 개인정보 처리 방침에서 명시한 범위 내에서 만 처리하며, 제3자(타인, 타기업 혹은 기관 등) 정보주체(고객)의 동의 없이 본래의 범위를 초과하여 처리하거나, 
제3자에게 제공하지 않습니다. 다만, 「개인정보보호법」 제17조, 제18조 및 법률의 특별한 규정 등에 해당하는 경우에 한하여 개인정보를 제3자에게 제공합니다.`
    },
    {
        title: '제 4조 [개인정보처리의 위탁]',
        content: `(주)피플히어로에서는 고객들께 더 나은 서비스를 제공하기 위하여 정보주체(고객)의 개인정보처리를 외부전문기관에 위탁할 수 있습니다.
  ① 개인정보 처리를 위탁하는 경우 위탁하는 업무의 내용과 수탁자를 정보주체(고객)(이)가 언제든지 쉽게 확인 할 수 있도록 회사의 홈페이지에 지속적으로 공개합니다. 
위탁하는 업무의 내용이나 수탁자가 변경 된 경우에도 홈페이지를 통하여 정보주체(고객)에게 알려드립니다.
  ② 피플히어로가 과실없이 서면, 전자우편, 팩스, 전화, 문자전송 또는 이에 상당하는 방법으로 정보주체에게 알릴 수 없는 경우에는 해당 사항을 인터넷 홈페이지에 30일 이상 게재합니다.
  ③ 피플히어로는 수탁자에게 다음의 각 호의 내용이 포함 된 문서에 의하여 개인정보의 처리 업무를 위탁하고 있으며, 당해 문서를 서면 또는 전자적으로 보관하여 정보주체(고객)의 권익을 보호합니다.
    1. 위탁업무의 목적 및 범위
    2. 위탁업무 수행 목적 외 개인정보 처리 금지에 관한 사항
    3. 재위탁 제한에 관한 사항
    4. 개인정보의 기술적, 관리적 보호조치에 관한 사항
    5. 개인정보에 대한 접근 권한 등 안전성 확보 조치에 관한 사항
    6. 위탁업무와 관련하여 보유하고 있는 개인정보의 관리 현황 점검 등 감독에 관한 사항
    7. 수탁자가 준수하여야 할 의무를 위반한 경우의 손해배상 등에 관한 사항
  ④ 취급 위탁현황`
    },
    {
        title: '제 5조[이용자 및 법정대리인의 권리/ 의무 및 행사 방법]',
        content: `① 이용자는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
   1. 개인정보 열람 요구
   2. 오류 등이 있을 경우 정정 요구
   3. 삭제 요구
   4. 처리 정지 요구
 ② 제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체없이 조치하겠습니다.
 ③ 이용자가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료 할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
 ④ 만 14세 미만 아동의 경우, 제1항에 따른 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이경우, 법정대리인은 이용자의 모든 권리를 가집니다.
 ⑤ 이용자는 정보통신망법, 개인정보보호법 등 관계법령을 위반하여 회사가 처리하고 있는 이용자 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.`
    },
    {
        title: ' 제6조 [수집하는 개인정보항목]',
        content: `(주) 피플히어로가 수집하는 개인정보는 이름, 핸드폰번호, 이메일 입니다.

필수동의항목: 이름, 카카오계정(전화번호)
선택동의항목: 이메일`
    },
    {
        title: '제 7조 [개인정보 자동 수집 장치의 설치/운영 및 거부]',
        content: `회사는 이용자 개개인에게 개인화되고 맞춤화 된 서비스를 제공하기 위해 이용자의 정보를 저장하고 수시로 불러오는 ‘쿠키(Cookie)’를 사용합니다.
    ① 쿠키의 사용 목적
회원과 비회원의 접속 빈도나 방문 시간 등의 분석, 이용자의 취향과 관심분야의 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스를 제공 합니다.
    ② 쿠키 설정 거부 방법
이용자는 쿠키 설치에 대해 거부할 수 있습니다. 단, 쿠키 설치를 거부하였을 경우 로그인이 필요한 일부 서비스의 이용이 어려울 수 있습니다.
- 쿠키 설치 허용 여부를 지정하는 방법은 다음과 같습니다.
o Internet Explorer를 사용하는 경우 (보기)
o Microsoft Edge를 사용하는 경우 (보기)
o Safari를 사용하는 경우 (보기)
o FireFox를 사용하는 경우 (보기)
o Chrome를 사용하는 경우 (보기)
1. Microsoft Edge 웹 브라우저의 경우 : 웹브라우저 상단 메뉴 > 설정 > 고급 설정 보기 > 쿠키 메뉴의 옵션 설정
2. Chrome 웹브라우저의 경우 : 웹브라우저 상단 메뉴 > 설정 > 고급 > 콘텐츠 설정 > 쿠키 메뉴의 옵션 설정`
    },
    {
        title: '제 8조 [개인정보의 파기]',
        content: `① (주)피플히어로는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. 
② (주)피플히어로는 다음 각호의 방법으로 개인정보를 파기합니다.
1. 파기절차
회원님이 입력한 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라 (보유 및 이용 기간 참조) 일정 기간 저장된 후 파기됩니다. 
동 개인정보는 법률에 의한 경우가 아니면 그 이외의 다른 목적으로 이용되지 않습니다
2. 파기기한
회원님의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에 파기하며 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 
사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.
3. 파기방법
종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하고, 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.`
    },
    {
        title: '제 9조 [개인정보의 안전성 확보조치]',
        content: `(주)피플히어로는 개인정보의 안전성 확보를 위해 다음 각호와 같이 조치를 취하고 있습니다. 
    1. 관리적 조치 : 내부관리계획 수립 및 시행, 직원 등에 대한 정기적 교육 
    2. 기술적 조치 : 개인정보처리시스템(또는 개인정보가 저장된 컴퓨터)의 비밀번호 설정 등 접근권한 관리, 백신소프트웨어 등 보안프로그램 설치, 개인정보가 저장된 파일의 암호화 
    3. 물리적 조치 : 개인정보가 저장된 장소의 시건, 출입통제 등`
    },
    {
        title: '제 10조 [개인정보 보호책임자]',
        content: `(주)피플히어로는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제를 처리하기 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. 
  ① 개인정보 보호 최고 책임자
    - 성명 : 남동현
    - 직급 : 대표
    - 연락처 : 053-853-8420
    - 이메일 : bigpos8420@naver.com
  ② 개인정보 보호 책임자
    - 성명 : 남동현
    - 직급 : 대표
    - 연락처 : 053-853-8420
    - 이메일 : bigpos8420@naver.com
③ 기타 개인정보침해에 대한 신고 또는 상담이 필요하신 경우에는 아래 기관으로 문의하시기 바랍니다.
    - 개인정보분쟁조정위원회전화 : 1833-6972, URL : http://www.kopico.go.kr
    - 개인정보침해신고센터전화 : (국번없이)118, URL : http://privacy.kisa.or.kr
    - 대검찰청 첨단범죄수사센터전화 : (국번없이)1301, URL : http://www.spo.go.kr
    - 경찰청 사이버안전국전화 : (국번없이)182, URL : https://ecrm.police.go.kr/minwon/main`
    },
    {
        title: '제 11조 [개인정보보호 교육]',
        content: `①교육목적
개인정보 보호책임자는 개인정보가 안전하게 관리될 수 있도록 개인정보 보호관리자 및 개인정보 취급자를 대상으로 개인정보보호 대책의 필요성을 이해시키기 위한 교육을 실시하여야 한다.
  ②교육대상 및 교육실시
  - 개인정보 보호관리자 : 연 1회 이상
  - 개인정보 취급자 : 연 1회 이상
  - 개인정보 보호책임자가 지정하는 자 : 연 1회 이상
  ③교육내용
  - 개인정보보호의 중요성 설명
  - 내부관리계획의 준수 및 이행
  - 개인정보보호 위반을 보고해야 할 필요성
  - 개인정보보호 관련자들의 금지 항목들
  - 개인정보보호 준수사항 이행 관련 절차 등
제 12조 [개인정보 처리방침 변경]
이 개인정보 처리 방침은 2022년 12월 19일부터 적용됩니다.`
    }
]
export default Privacy;