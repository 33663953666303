import React, { SetStateAction } from "react";
import "./css/index.css";
import { UserAgentType } from "../../interface/common/common.inferface";
import InquiryInput from "../../components/InquiryInput/InquiryInput";
import AgreementCheckContainer from "../../components/AgreementCheck/container/AgreementCheckContainer";
import { Business, CounselInquiry } from "../../interface/inquiry/inquiry";
import { ReactComponent as CallSvg } from "./svgs/call.svg";
import { ReactComponent as ConnectSvg } from "./svgs/connect.svg";
type Props = {
  title: string;
  explanationTitle: string;
  inputArr: {
    label: string;
    name: string;
    placeholder: string;
    maxLength: number;
    minLength: number;
    type: string;
    value: string;
    subLabel?: string;
  }[];
  explanationSub: string;
  inquiryData: CounselInquiry | Business;
  img: string;
  userAgent: UserAgentType;
  termsCheck: number[];
  setTermsCheck: React.Dispatch<SetStateAction<number[]>>;
  ChangeUpdate: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  InquirySubmit: () => void;
  isValid: () => boolean;
};
const InquiryForm = ({
  title,
  explanationTitle,
  explanationSub,
  img,
  userAgent,
  termsCheck,
  inputArr,
  InquirySubmit,
  inquiryData,
  setTermsCheck,
  ChangeUpdate,
  isValid,
}: Props) => {
  return (
    <main className="inquiry-form-page">
      <section className="inquiry-title-section">
        <h2 className="inquiry-title">{title}</h2>
      </section>
      <section className="inquiry-input-section">
        <div className="inquiry-explanation-box">
          <div className="explanation-text-box">
            <p className="explanation-title">{explanationTitle}</p>
            <p className="explanation-sub">{explanationSub}</p>
          </div>
          <div className="img-box">
            {img === "call" ? <CallSvg /> : <ConnectSvg />}
          </div>
        </div>
        <div className="input-wrapper">
          <InquiryInput
            inputArr={inputArr}
            data={inquiryData}
            ChangeUpdate={ChangeUpdate}
          />
          <div className="agreement-check-wrapper">
            {checkBoxArr.map(({ text }, idx) => (
              <AgreementCheckContainer
                termsCheck={termsCheck}
                key={idx}
                id={idx}
                text={text}
                setTermsCheck={setTermsCheck}
              />
            ))}
            <button
              onClick={InquirySubmit}
              disabled={isValid() ? false : true}
              className={`
                submit-btn ${isValid() ? "" : "disable-btn"}
                `}
            >
              문의하기
            </button>
          </div>
        </div>
      </section>
    </main>
  );
};

export default InquiryForm;

const checkBoxArr = [
  {
    text: "온라인 문의를 위한 개인정보 수집 및 이용에 동의합니다",
  },
  {
    text: "마케팅 정보 수신에 동의합니다",
  },
];
