import React from "react";
import "./css/index.css";
import { Link } from "react-router-dom";

type Props = {
  text: string;
  id: number;
  termsCheck: number[];
  TermsClicked: (id: number) => void;
};

const AgreementCheck = ({ termsCheck, text, TermsClicked, id }: Props) => {
  return (
    <div className="agreement-check">
      <div className="check-box-wrapper">
        <button
          className={`check-box ${
            termsCheck.includes(id) && "active-check-box"
          }`}
          onClick={() => TermsClicked(id)}
        >
          {termsCheck.includes(id) ? (
            <img
              src="/assets/common/check.png"
              srcSet="/assets/common/check@2x.png 2x, 
                /assets/common/check@3x.png 3x,
            "
              alt="check-box"
            />
          ) : (
            <img
              src="/assets/common/no-check.png"
              srcSet="/assets/common/no-check@2x.png 2x,
                    /assets/common/no-check@3x.png 3x,
                "
              alt="no-check"
            />
          )}
        </button>
        <p className="text">{text}</p>
      </div>
      <Link className="show-link" to={"/privacy"}>
        자세히 보기
      </Link>
    </div>
  );
};

export default AgreementCheck;
