import React from 'react'
import "./css/index.css";
import { UserAgentType } from '../../interface/common/common.inferface';
type Props={
    userAgent:UserAgentType;
    img:string;
    sub:string;
    title:string;
}
const ContentOddCard = ({userAgent,img,sub,title}:Props) => {
  return (
    <div className="content-odd-card">
      <img
        src={`/assets/mainsection2/${userAgent}/${img}.png`}
        srcSet={`
                  /assets/mainsection2/${userAgent}/${img}@2x.png 2x,
                  /assets/mainsection2/${userAgent}/${img}@3x.png 3x
                `}
        alt={img}
      />
      <div className="content-box">
        <p className="sub-text">{sub}</p>
        <p className="main-text">{title}</p>
      </div>
    </div>
  );
}

export default ContentOddCard