import React from 'react'
import "./css/index.css";
import useIntersectionObserver from '../../hooks/UseIntersectionObserver';
import { UserAgentType } from '../../interface/common/common.inferface';

type Props={
    title:string;
    sub:string;
    userAgent:UserAgentType;
    src:string;
}

const MainSection11Card = ({title,sub,userAgent,src}:Props) => {
   const targetRef = useIntersectionObserver(0.1);
   return (
     <div className="main-section11-card  hidden-section" ref={targetRef}>
       <div className="card-text">
         <p className="text">{title}</p>
         <p className="sub">{sub}</p>
       </div>
       <img
         src={`/assets/mainsection11/${userAgent}/${src}.png`}
         srcSet={`/assets/mainsection11/${userAgent}/${src}@2x.png 2x, /assets/mainsection11/${userAgent}/${src}@3x.png 3x`}
         alt={src}
       />
     </div>
   );
}

export default MainSection11Card