import React from 'react'
import "./css/index.css";
type Props={
    content:string;
}
const StoreCard = ({content,}:Props) => {
  return (
    <div className="store-card">
      <img
        src={`/assets/common/check-box.png`}
        srcSet={`
                    /assets/common/check-box@2x.png 2x,
                    /assets/common/check-box@3x.png 3x,
                `}
        alt="check-box"
        className='check-box'
      />
      <p className='content-text'>{content}</p>
    </div>
  );
}

export default StoreCard