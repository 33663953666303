import React, { useEffect, useState } from "react";
import MainBanner from "../MainBanner";
import { UserAgentType } from "../../../interface/common/common.inferface";
type Props = {
  userAgent: UserAgentType;
};
const MainBannerContainer = ({ userAgent }: Props) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);
  return (
    <MainBanner
      userAgent={userAgent}
      currentIndex={currentIndex}
      images={images}
    />
  );
};

export default MainBannerContainer;
const images = ["mainbanner01", "mainbanner02", "mainbanner03"];
