import React, { useEffect, useState } from "react";
import "./core/core.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./page/Main/Main";
import Industry from "./page/Industry/Industry";
import SkillContainer from "./page/Skill/container/SkillContainer";
import CardTerminal from "./page/CardTerminal/CardTerminal";
import Download from "./page/Download/Download";
import { UserAgentType } from "./interface/common/common.inferface";
import FaqContainer from "./page/Faq/container/FaqContainer";
import Procedure from "./page/Procedure/Procedure";
import LoginPopup from "./components/LoginPopup/LoginPopup";
import Login from "./page/Login/Login";
import Toast from "./components/Toast/Toast";
import Loading from "./components/Loading/Loading";
import UserRoutes from "./routes/UserRoutes";
import Privacy from "./page/Privacy/Privacy";
import AlertTalkContainer from "./page/AlertTalk/container/AlertTalkContainer";
import HeaderContainer from "./components/Header/container/HeaderContainer";
import InquiryRoutes from "./routes/InquiryRoutes";
function App() {
  const [userAgent, setUserAgent] = useState<UserAgentType>("pc");
  useEffect(() => {
    function change(e: any) {
      const width = e.target.innerWidth;
      if (width < 1440 && width > 767 && userAgent !== "tablet") {
        setUserAgent("tablet");
      }
      if (width < 768 && userAgent !== "mb") {
        setUserAgent("mb");
      }
      if (width > 1439 && userAgent !== "pc") {
        setUserAgent("pc");
      }
    }
    window.addEventListener("resize", change);
    return () => {
      window.removeEventListener("resize", change);
    };
  }, [userAgent]);
  useEffect(() => {
    const size = window.innerWidth;
    if (size < 1440 && size > 767) {
      setUserAgent("tablet");
    }
    if (size < 768) {
      setUserAgent("mb");
    }
    if (size > 1439) {
      setUserAgent("pc");
    }
    return () => {};
  }, []);
  return (
    <BrowserRouter>
      <HeaderContainer userAgent={userAgent} />
      <Routes>
        <Route path="/*" element={<Main userAgent={userAgent} />} />
        {/* 도입 업종 */}
        <Route path="/industry" element={<Industry userAgent={userAgent} />} />
        {/* 주요 기능 */}
        <Route
          path="/skill"
          element={<SkillContainer userAgent={userAgent} />}
        />
        {/* 카드 단말기 */}
        <Route
          path="/terminal"
          element={<CardTerminal userAgent={userAgent} />}
        />
        {/* 다운로드 */}
        <Route path="/down" element={<Download userAgent={userAgent} />} />
        {/* 가맹절차 */}
        <Route path="/procedure" element={<Procedure />} />
        {/* FAQ */}
        <Route path="/faq" element={<FaqContainer />} />
        {/* 유저만 접근 가능한 라우트 */}
        <Route path="/user/*" element={<UserRoutes userAgent={userAgent} />} />
        <Route path="/login" element={<Login />} />
        {/* 개인정보 처리방침 */}
        <Route path="/privacy" element={<Privacy />} />
        {/* 알림톡 서비스 */}
        <Route
          path="/talk"
          element={<AlertTalkContainer userAgent={userAgent} />}
        />

        {/* 상담 라우터 */}
        <Route
          path="/counsel/*"
          element={<InquiryRoutes userAgent={userAgent} />}
        />
      </Routes>
      <Toast />
      <Loading />
      <LoginPopup />
    </BrowserRouter>
  );
}

export default App;
