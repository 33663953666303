import React, { ReactNode } from 'react'
import { ReactComponent as ArrowSvg } from "./svg/arrow.svg";
import "./css/index.css";
type Props={
    crtIdx:number;
    idx:number;
    Active:(idx:number)=>void;
    svg:ReactNode;
    title:string;
    sub:string;
}
const DynamicContentButton = ({crtIdx,idx,Active,svg,title,sub}:Props) => {
  return (
    <button
      className={`menu-btn ${idx === crtIdx && "color-btn"}`}
      onClick={() => Active(idx)}
    >
      {svg}
      <div className="text-box">
        <p className="title">{title}</p>
        <p className="sub">{sub}</p>
      </div>
      <ArrowSvg className="arrow" />
    </button>
  );
}

export default DynamicContentButton