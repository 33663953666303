import "./css/index.css";
import { Link } from "react-router-dom";
import { UserAgentType } from "../../interface/common/common.inferface";
import InquiryToggle from "../InquiryToggle/InquiryToggle";
import PcHeaderContainer from "./container/PcHeaderContainer";
import HeaderToggle from "../HeaderToggle/HeaderToggle";
type Props = {
  userAgent: UserAgentType;
  isToggleOpen: boolean;
  activeText: number | null;
  isInquiryOpen: boolean;
  isLogin: string | undefined;
  LogOutBtn: () => void;
  LoginBtnClicked:()=>void;
  ToggleOpenClick: () => void;
  MenuNavigate: (idx: number, link: string) => void;
};
const Header = ({
  userAgent,
  isToggleOpen,
  activeText,
  isInquiryOpen,
  isLogin,
  LogOutBtn,
  LoginBtnClicked,
  ToggleOpenClick,
  MenuNavigate,
}: Props) => {
  return (
    <header className="header-wrapper">
      {userAgent === "pc" && (
        <PcHeaderContainer
          LogOutBtn={LogOutBtn}
          LoginBtnClicked={LoginBtnClicked}
          headerLists={menuArr}
        />
      )}
      {(userAgent === "tablet" || userAgent === "mb") && (
        <div className="mb-header">
          {isToggleOpen ? (
            <HeaderToggle
              menuArr={menuArr}
              activeText={activeText}
              isInquiryOpen={isInquiryOpen}
              ToggleOpenClick={ToggleOpenClick}
              MenuNavigate={MenuNavigate}
            />
          ) : (
            <div className="mb-header-container">
              <div className="logo-img-container">
                <Link to="/">
                  <img
                    src="/assets/skpos-logo.png"
                    srcSet="/assets/skpos-logo@2x.png 2x, /assets/skpos-logo@3x.png 3x"
                    alt="skpos"
                  />
                </Link>
              </div>
              <div className="mb-header-right-section">
                <div className="auth-container">
                  <div className={`auth-box ${isLogin && "logout-box"}`}>
                    {isLogin ? (
                      <button onClick={LogOutBtn}>로그아웃</button>
                    ) : (
                      <button onClick={LoginBtnClicked}>로그인</button>
                    )}
                  </div>
                </div>
                <button className="hamburger-menubar" onClick={ToggleOpenClick}>
                  <img src="/assets/hamburger.svg" alt="hamburger" />
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </header>
  );
};
export default Header;
const menuArr = [
  // {
  //   title: "알림톡",
  //   link: "",
  // },
  {
    title: "도입업종",
    link: "/industry",
  },
  {
    title: "다운로드",
    link: "/down",
  },
  {
    title: "자주묻는 질문",
    link: "/faq",
  },
  {
    title: "상담문의",
    link: "#",
  },
];
