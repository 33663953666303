import React from "react";
import "./css/index.scss";
import { Link } from "react-router-dom";
type Props = {
  colorText: string;
  mainText: React.ReactNode;
  subText?: React.ReactNode;
  bgHeight?:string;
  src: string;
  srcSet: string;
  type?: "button" | "inquiry";
  buttonText?: string;
  Active?: () => void;
  href?: string;
  btnColor?:string;
};
const PosType = ({
  colorText,
  mainText,
  subText,
  src,
  btnColor,
  srcSet,
  type,
  buttonText,
  href,
  bgHeight,
  Active,
}: Props) => {
  return (
    <section
      className={`section1 ${type === "inquiry" && "inquiry"}`}
      style={{
        height: `${bgHeight}px`,
      }}
    >
      <div className={`section1-wrapper ${type === "button" && "center-wrapper"}`}>
        <div className="detail-container">
          <div className={`color-text`}>{colorText}</div>
          <div className="main-text">{mainText}</div>
          <div className="sub-text">{subText}</div>
          {type === "button" && href && (
            <Link
              to={href}
              className="button"
              onClick={Active}
              rel="noreferrer"
              style={{
                backgroundColor: btnColor,
                color: btnColor && "white",
              }}
            >
              {buttonText}
            </Link>
          )}
          {type === "button" && Active && (
            <button className="button" onClick={Active}>
              {buttonText}
            </button>
          )}
        </div>
      </div>
      <img
        src={src}
        srcSet={srcSet}
        alt=""
        className="pos-type-background-img"
      />
    </section>
  );
};
export default PosType;
