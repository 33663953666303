import "./css/index.css";

type Props = {
  className: string;
  title: string;
  subTitle: string;
};
const Bubble = ({ className, title, subTitle }: Props) => {
  return (
    <div className={`bubble-comp ${className}`}>
      <p className="title">{title}</p>
      <p className="sub-title">{subTitle}</p>
    </div>
  );
};

export default Bubble;
