import React from "react";
import "./css/index.css";
import { UserAgentType } from "../../interface/common/common.inferface";

type Props = {
  title: string;
  subtitle: string;
  src: string;
  subSrc?: string;
  userAgent: UserAgentType;
  bgColor: string;
};
const AlertCardInfo = ({
  title,
  subtitle,
  src,
  subSrc,
  userAgent,
  bgColor,
}: Props) => {
  return (
    <div className="alert-card-info" style={{ backgroundColor: bgColor }}>
      <div className="content-wrapper">
        <div className="title-box">
          <p className="title">{title}</p>
          {subSrc && (
            <img
              className="sub-img"
              src={`/assets/alerttalk/section04/${userAgent}/${subSrc}.png`}
              srcSet={`
                  /assets/alerttalk/section04/${userAgent}/${subSrc}@2x.png 2x,
                  /assets/alerttalk/section04/${userAgent}/${subSrc}@3x.png 3x,
                `}
              alt=""
            />
          )}
        </div>
        <p className="subtitle">{subtitle}</p>
      </div>
      <div className="img-wrapper">
        <img
          src={`/assets/alerttalk/section04/${userAgent}/${src}.png`}
          srcSet={`
                /assets/alerttalk/section04/${userAgent}/${src}@2x.png 2x,
                /assets/alerttalk/section04/${userAgent}/${src}@3x.png 3x,
                `}
          alt="card-info"
        />
      </div>
    </div>
  );
};

export default AlertCardInfo;
