import React, { useCallback, useReducer, useState } from "react";
import InquiryForm from "../InquiryForm";
import { UserAgentType } from "../../../interface/common/common.inferface";
import { CounselInquiry } from "../../../interface/inquiry/inquiry";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../config/firebase";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducer";
import { formatPhoneNumber } from "../../../utils/utils";
import { InquiryInitial } from "../../InquiryWrite/reducer/InquiryReducer";
import { ToastChange } from "../../../reducer/config";
import { AuthType } from "../../../reducer/auth";
type Props = {
  userAgent: UserAgentType;
};
const InquiryContainer = ({ userAgent }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uid, email } = useSelector<RootState, AuthType>(
    (state) => state.auth
  );

  const [inquiryData, setInquiryData] = useState<CounselInquiry>({
    name: "",
    tel: "",
    sectors: "",
    comno: "",
    content: "",
  });
  const [termsCheck, setTermsCheck] = useState<number[]>([]);

  const ChangeUpdate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setInquiryData((prev) => ({
        ...prev,
        [name]: name === "tel" ? formatPhoneNumber(value) : value,
      }));
    },
    [inquiryData]
  );
  const InquirySubmit = useCallback(async () => {
    const { name, tel, sectors, comno, content } = inquiryData;
    const obj = Object.assign(InquiryInitial(), {
      name,
      email,
      tel,
      sectors,
      comno,
      content,
      timestamp: Date.now(),
      uid,
    });
    await addDoc(collection(db, "inquiry"), obj);
    dispatch(
      ToastChange({
        active: true,
        content: "문의를 완료하였습니다",
      })
    );
    return navigate("/user/inquiry");
  }, [inquiryData, termsCheck, email]);

  const isValid = useCallback(() => {
    const { sectors, tel, name, content } = inquiryData;
    if (termsCheck.length === 2 && name && tel && sectors && content) {
      return true;
    }
    return false;
  }, [inquiryData, termsCheck]);

  const inputArr = [
    {
      label: "성명",
      name: "name",
      placeholder: "내용을 입력해주세요",
      maxLength: 7,
      minLength: 2,
      type: "text",
      value: inquiryData.name,
    },
    {
      label: "연락처",
      name: "tel",
      placeholder: "내용을 입력해주세요",
      maxLength: 13,
      minLength: 13,
      type: "text",
      value: inquiryData.tel,
    },
    {
      label: "업종",
      name: "sectors",
      placeholder: "어떤 업종인지 알려주세요 (ex. 카페, 꽃집 등)",
      maxLength: 13,
      minLength: 13,
      type: "text",
      value: inquiryData.sectors,
    },
    {
      label: "사업자등록번호",
      subLabel: "선택",
      name: "comno",
      placeholder: "운영하고 계신 사업이 있다면 알려주세요.",
      maxLength: 10,
      minLength: 10,
      value: inquiryData.comno,
      type: "string",
    },
  ];

  if (uid) {
    return (
      <InquiryForm
        title="상담 문의"
        inputArr={inputArr}
        explanationTitle={`매장에 맞는 서비스를
추천해드려요`}
        explanationSub={`포스 앱, 알림톡, 카드단말기, 포스기 등
궁금하신 내용을 작성해주세요.
모든 카드사 가맹은 무료로 진행해드려요`}
        img="call"
        userAgent={userAgent}
        inquiryData={inquiryData}
        termsCheck={termsCheck}
        setTermsCheck={setTermsCheck}
        InquirySubmit={InquirySubmit}
        ChangeUpdate={ChangeUpdate}
        isValid={isValid}
      />
    );
  }
  return <></>;
};

export default InquiryContainer;
