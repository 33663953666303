import React, { useCallback, useReducer, useState } from "react";
import InquiryForm from "../InquiryForm";
import { UserAgentType } from "../../../interface/common/common.inferface";
import { Business } from "../../../interface/inquiry/inquiry";
import { useDispatch, useSelector } from "react-redux";
import { ToastChange } from "../../../reducer/config";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../config/firebase";
import { formatPhoneNumber } from "../../../utils/utils";
import {
  InquiryInitial,
  InquiryReducer,
} from "../../InquiryWrite/reducer/InquiryReducer";
import { RootState } from "../../../reducer";

type Props = {
  userAgent: UserAgentType;
};

const PartnershipContainer = ({ userAgent }: Props) => {
  const dispatch = useDispatch();
  const uid = useSelector<RootState, string | undefined>(
    (state) => state.auth.uid
  );
  const [termsCheck, setTermsCheck] = useState<number[]>([]);
  const [inquiryData, setInquiryData] = useState<Business>({
    name: "",
    tel: "",
    company: "",
    comno: "",
    content: "",
  });
  const ChangeUpdate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value, name } = e.target;
      setInquiryData((prev) => ({
        ...prev,
        [name]: name === "tel" ? formatPhoneNumber(value) : value,
      }));
    },
    [inquiryData]
  );

  const InquirySubmit = useCallback(async () => {
    try {
      const { name, tel, company, comno, content } = inquiryData;
      const obj = Object.assign(InquiryInitial(), {
        name,
        tel,
        company,
        comno,
        content,
        timestamp: Date.now(),
        uid,
      });
      await addDoc(collection(db, "business"), obj);
      setInquiryData({
        name: "",
        tel: "",
        company: "",
        comno: "",
        content: "",
      });
      return dispatch(
        ToastChange({
          active: true,
          content: "문의를 완료하였습니다",
        })
      );
    } catch (error) {
      return dispatch(
        ToastChange({
          active: true,
          content: "문의를 신청하는 중 오류가 발생했습니다.",
        })
      );
    }
  }, [dispatch, inquiryData, termsCheck]);

  const isValid = useCallback(() => {
    const { company, tel, name, content } = inquiryData;
    if (termsCheck.length === 2 && name && tel && company && content) {
      return true;
    }
    return false;
  }, [inquiryData, termsCheck]);

  const inputArr = [
    {
      label: "성명",
      name: "name",
      placeholder: "내용을 입력해주세요",
      maxLength: 7,
      minLength: 2,
      type: "text",
      value: inquiryData.name,
    },
    {
      label: "연락처",
      name: "tel",
      placeholder: "내용을 입력해주세요",
      maxLength: 13,
      minLength: 13,
      type: "text",
      value: inquiryData.tel,
    },
    {
      label: "상호",
      name: "company",
      placeholder: "어떤 업종인지 알려주세요 (ex. 카페, 꽃집 등)",
      maxLength: 13,
      minLength: 13,
      type: "text",
      value: inquiryData.company,
    },
    {
      label: "사업자등록번호",
      subLabel: "선택",
      name: "comno",
      placeholder: "운영하고 계신 사업이 있다면 알려주세요.",
      maxLength: 10,
      minLength: 10,
      value: inquiryData.comno,
      type: "string",
    },
  ];
  return (
    <InquiryForm
      inputArr={inputArr}
      title="사업 제휴 문의"
      explanationTitle={`가맹점 사장님을
직접 연결해드려요`}
      explanationSub={`부가서비스로 수익을 창출하세요`}
      inquiryData={inquiryData}
      img="coneect"
      userAgent={userAgent}
      termsCheck={termsCheck}
      setTermsCheck={setTermsCheck}
      ChangeUpdate={ChangeUpdate}
      InquirySubmit={InquirySubmit}
      isValid={isValid}
    />
  );
};

export default PartnershipContainer;
