import React, { useEffect, useRef } from "react";
import SliderContent from "../SliderContent";
import { UserAgentType } from "../../../interface/common/common.inferface";

type Props = {
  userAgent: UserAgentType;
};

const SliderContentContainer = ({ userAgent }: Props) => {
  return <SliderContent content={content} userAgent={userAgent} />;
};

export default SliderContentContainer;

const content = [
  {
    sub: "주)지엘코리아대표 | 72세",
    title: `“마케팅이 이렇게 편해요
컴퓨터 초보가 사용하기도
정말 쉬워요”`,
    img: "slider-content01",
  },
  {
    sub: "도매 | 복푸드",
    title: `“판매 상품 목록이 많아도
결제가 빠르고
오류 한번 없었어요”`,
    img: "slider-content02",
  },
  {
    sub: "슈퍼 | 하나마트",
    title: `“마케팅에 상품 이미지를
활용하니 확실히 고객 반응도
더 좋고 매출도 올랐어요”`,
    img: "slider-content03",
  },
  {
    sub: "진량농협하나로마트",
    title: `“시스템이
안정적이라
믿음직스러워요”`,
    img: "slider-content04",
    bgColor: "#01A0A0",
  },
  {
    sub: "정육점 | 미소축산",
    title: `“10분이면 마케팅 끝
마케팅하면 매출이 쑥!쑥!
올라요. 안할 이유가 없어요”`,
    img: "slider-content05",
  },
  {
    sub: "도매 | 주원유통",
    title: `“수천가지 상품 재고도
실시간으로 확인할
수 있어 좋아요"`,
    img: "slider-content06",
  },
];
