import { useEffect, useRef } from "react";

const useIntersectionObserver = (threshold: number) => {
  const targetRef = useRef(null);
  const isIntersecting = useRef(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("slide-up");
          isIntersecting.current = true;
          observer.unobserve(entry.target); // 애니메이션이 한 번만 실행되도록 설정
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: threshold, // 기본값은 50%로 설정 가능
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [threshold]);

  return targetRef;
};

export default useIntersectionObserver;
