import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PartnershipContainer from '../page/InquiryForm/container/PartnershipContainer';
import { UserAgentType } from '../interface/common/common.inferface';

type Props={
    userAgent:UserAgentType;
}
const InquiryRoutes = ({ userAgent }:Props) => {
  return (
    <Routes>
     
      {/* 사업제휴 문의 */}
      <Route
        path="/business"
        element={<PartnershipContainer userAgent={userAgent} />}
      />
    </Routes>
  );
};

export default InquiryRoutes