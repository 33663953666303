import React from 'react'
import "./css/index.css";
import { UserAgentType } from '../../interface/common/common.inferface';
import Circle from '../Circle/Circle';
type Props={
    userAgent:UserAgentType;
}
const PcTopTrendBox = ({userAgent}:Props) => {
  return (
    <div className="pc-top-trend-box">
      <div className="circle-wrapper">
        <img
          className="line"
          src={`/assets/mainsection4/${userAgent}/topline.png`}
          srcSet={`
                    /assets/mainsection4/${userAgent}/topline@2x.png 2x,
                    /assets/mainsection4/${userAgent}/topline@3x.png 3x,
                    `}
        />
        {circleArr.map(({ text }, idx) => (
          <Circle text={text} key={idx} />
        ))}
      </div>
      <div className="dot-line-box">
        <div className="dot-line " />
        <div className="sm-circle" />
      </div>
    </div>
  );
}

export default PcTopTrendBox;

const circleArr = [
  {
    text: `오프라인
매출`,
  },
  {
    text: `상품`,
  },
  {
    text: `온라인
주문`,
  },
];