import React, { Dispatch, SetStateAction } from "react";
import "./css/index.css";
import PosType from "../../components/PosType/PosType";
import Technology from "../../components/Technology/Technology";
import Bottom from "../../components/Bottom/Bottom";
import { UserAgentType } from "../../interface/common/common.inferface";
import { VideoInterface } from "../../interface/list/list.interface";
import UseLinkLog from "../../hooks/UseLinkLog";
import FooterContainer from "../../components/Footer/container/FooterContainer";

type Props = {
  userAgent: UserAgentType;
  data: VideoInterface[];
  nowCategory: number;
  setnowCategory: Dispatch<SetStateAction<number>>;
};

const Skill = ({ userAgent, nowCategory, setnowCategory, data }: Props) => {
  const { UpdateLinkLog } = UseLinkLog();
  return (
    <main className="skill-page">
      <PosType
        colorText="주요기능"
        mainText={
          <>
        우리가게, 업종에 맞는 <br/>
        포스 솔루션을 선택하세요
          </>
        }
        // href="https://smartstore.naver.com/shpos/category/1730b07eaad3452e8c55f4acf984aad2?cp=1"
        subText="편리한 기능을 만나보세요"
        src="/assets/skill/posimg.png"
        srcSet="/assets/skill/posimg@2x.png 2x, /assets/skill/posimg@3x.png 3x"
      />
      {/* <section className="card-terminal-section">
        <div className="card-terminal-wrapper">
          <div className="title-container">
            <div className="color-text">다양한 단말기</div>
            <div className="bold-text">
              내 가게에 딱 맞는 <br />
              카드단말기
            </div>
          </div>

          <div className="terminal-container">
            {termianlUseArr.map((item, idx) => (
              <div key={idx} className="terminal">
                <div className="img-wrapper">
                  <img
                    className="terminal-img"
                    src={item.src}
                    srcSet={item.srcSet}
                    alt="terminal"
                  />
                </div>
                <div className="container">
                  <div className="terminal-title-container">
                    <p className="terminal-title">{item.title}</p>
                    <p className="terminal-subtitle">{item.subtitle}</p>
                  </div>
                  <div className="store">
                    <p className="price">
                      {item.text && <span>{item.text}</span>}
                      {item.price}
                    </p>
                    <a
                      href={item.link}
                      className="naver-store-button"
                      target={"_blank"}
                      rel="noreferrer"
                      onClick={() => {
                        UpdateLinkLog(item.log);
                      }}
                    >
                      네이버 스토어 바로가기
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      <section className="category-section">
        <div className="category-wrapper">
          <div className="category-container">
            <div className="category-title-container">
              <p className="category-subtitle">최적의 사용자 UI</p>
              <p className="category-title">
                {userAgent === "pc"
                  ? `빠른 응답성과 최적의 사용자 인터페이스를 제공합니다`
                  : userAgent === "tablet"
                  ? `빠른 응답성과 최적의 사용자
인터페이스를 제공합니다`
                  : userAgent === "mb" &&
                    `빠른 응답성과 최적의 사용자
인터페이스를 제공합니다`}
              </p>
            </div>
            <div className="category-detail-wrapper">
              <div className="category-menu-bar">
                <div className="menubar-container">
                  {categoryMenuArr.map((item, idx) => (
                    <button
                      className={`category-box ${
                        nowCategory === idx && "now-category-box"
                      }`}
                      key={idx}
                      onClick={() => {
                        setnowCategory(idx);
                      }}
                    >
                      {item}
                    </button>
                  ))}
                </div>
              </div>
              <div className="category-detail-card-container">
                {data.map((item, idx) => (
                  <div className="category-detail-box" key={idx}>
                    <div className="category-detail-title-container">
                      <p className="color-text">
                        {categoryMenuArr[item.category]}
                      </p>
                      <p className="detail-text">{item.title}</p>
                      <p className="detail-subtext">{item.subtitle}</p>
                    </div>
                    {item.video ? (
                      <video
                        className="video"
                        width={userAgent === "pc" ? 262 : 302}
                        height={userAgent === "pc" ? 172 : 216}
                        src={item.video.url}
                        controls
                      />
                    ) : (
                      <iframe
                        className="video"
                        width={userAgent === "pc" ? 262 : 302}
                        height={userAgent === "pc" ? 172 : 216}
                        src={item.link}
                        title="YouTube video player"
                        allowFullScreen
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Technology />
      <Bottom />
      < FooterContainer/>
    </main>
  );
};

export default Skill;

const termianlUseArr = [
  {
    title: "신용 IC 카드 단말기",
    subtitle: (
      <>
        IC 카드 마그네틱 카드
        <br />
        애플페이, 삼성페이, 제로페이 결제 가능
      </>
    ),
    price: "41,000 원",
    src: "/assets/skill/terminal/terminal.png",
    srcSet:
      "/assets/skill/terminal/terminal@2.png 2x, /assets/skill/terminal/terminal@3.png 3x",
    log: "신용 IC 카드 단말기",
    link: "https://smartstore.naver.com/shpos/products/5321068349",
  },
  {
    title: (
      <>
        휴대용 무선
        <br />
        블루투스
        <br /> 카드 단말기
      </>
    ),
    subtitle: "영수증 출력 가능",
    price: "68,000 원",
    src: "/assets/skill/terminal/terminal2.png",
    srcSet:
      "/assets/skill/terminal/terminal2@2x.png 2x, /assets/skill/terminal/terminal2@3x.png 3x",

    log: "휴대용 무선 블루투스 카드 단말기",
    link: "https://smartstore.naver.com/shpos/products/5361741229",
  },
  {
    title: "간편한 패키지 상품, 매장포스",
    subtitle: (
      <>
        최신형 포스기, OS : Win 10
        <br />
        함께 제공되는 부가장비 : 영수증 프린터, 핸드 바코드 스캐너,
        <br />
        돈 박스, 서명 패드 포스 프로그램 무료 셋팅, <br />
        카드 가맹점 가입 무료 <br /> 옵션 : 고객용 듀얼 모니터
      </>
    ),
    text: "국내최저가",
    price: "600,000 원",
    src: "/assets/skill/terminal/terminal3.png",
    srcSet:
      "/assets/skill/terminal/terminal3@2.png 2x, /assets/skill/terminal/terminal3@3.png 3x",
    log: "매장 포스",
    link: "https://www.shpos.co.kr/product/2f64dc2f-7c50-4912-9267-ec079f0385d3-62",
  },
];

const categoryMenuArr = [
  "편리한 기능",
  "상품장",
  "매입",
  "판매",
  "고객",
  "재고",
  "알림톡",
];
