import React from 'react'
import "./css/index.css";
import { UserAgentType } from '../../interface/common/common.inferface';
import Bubble from '../Bubble/Bubble';
import Circle from '../Circle/Circle';

type Props={
    userAgent:UserAgentType;
};

const TrendBottomBox = ({userAgent}:Props) => {
  return (
    <div className="trend-bottom-box">
      <div className="dot-line-box">
        <div className="sm-circle" />
        <div className="dot-line" />
      </div>
      <div className="circle-wrapper">
        <img
          className="line"
          src={`/assets/mainsection4/${userAgent}/bottomline.png`}
          srcSet={`
                    /assets/mainsection4/${userAgent}/bottomline@2x.png 2x,
                    /assets/mainsection4/${userAgent}/bottomline@3x.png 3x,
                    `}
        alt='bottom-line'
        />
        {circleArr.map(({ text }, idx) => (
          <Circle text={text} key={idx} color="#01A0A0" />
        ))}
      </div>
      <Bubble
        title="스마트스토어(베타)"
        subTitle={`네이버 스마트스토어 매출 자료를
SKPOS로 통합 관리`}
        className="bottom-bubble"
      />
    </div>
  );
}

export default TrendBottomBox;

const circleArr = [
  {
    text: "마케팅",
  },
  {
    text: "회원",
  },
  {
    text: `스마트
스토어`,
  },
];