import React from "react";
import "./css/index.css";
import { UserAgentType } from "../../../../interface/common/common.inferface";
import AlertCardInfo from "../../../../components/AlertCardInfo/AlertCardInfo";
import AlertTitle from "../../../../components/AlertTitle/AlertTitle";
import useIntersectionObserver from "../../../../hooks/UseIntersectionObserver";
type Props = {
  userAgent: UserAgentType;
};

const Section04 = ({ userAgent }: Props) => {
      const ref = useIntersectionObserver(0.3);

  const cardInfoArr = [
    {
      title: userAgent !== "tablet" ? (
        `전단 제작시, 5만개 이상
상품 이미지 제공`
      ) : (
        `전단 제작시, 5만개 이상 상품
이미지 제공`
      ),
      subtitle: `상품 바코드를 자동 생성이 가능합니다
수량이 많아도 문제가 없습니다`,
      src: "section04-2",
      bgColor: "#E6FBF7",
    },
    {
      title: userAgent === "pc" ? (
        `상품 및 회원
엑셀 업로드`
      ) : (
        `상품 및 회원 엑셀 업로드`
      ),
      subtitle: `상품별로 이익율을 설정해보세요 
원가와 이익금을 알아서 계산해드립니다`,
      src: "section04-3",
      subSrc: "excel",
      bgColor: "#CDF7EF",
    },
  ];
  return (
    <section ref={ref} className="alert-section04 hidden-section">
      <div className="section04-wrapper">
        <AlertTitle
          title={`마케팅을 펼칠 수 있는
웹사이트 무료 제공`}
          subtitle={`알림톡으로 전단을 바로 확인하고
구매/결제까지 한번에!`}
        />
        <div className="img-wrapper">
          <img
            src={`/assets/alerttalk/section04/${userAgent}/section04-1.png`}
            srcSet={`
                    /assets/alerttalk/section04/${userAgent}/section04-1@2x.png 2x,
                    /assets/alerttalk/section04/${userAgent}/section04-1@3x.png 3x
                `}
            alt="section04-1"
          />
        </div>
        <div className="card-wrapper">
          {cardInfoArr.map(({ title, subtitle, src, subSrc, bgColor }, idx) => (
            <AlertCardInfo
              title={title}
              subtitle={subtitle}
              src={src}
              subSrc={subSrc}
              key={idx}
              bgColor={bgColor}
              userAgent={userAgent}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Section04;
