import React from 'react'
import "./css/index.css";
import Circle from '../Circle/Circle';
import { UserAgentType } from '../../interface/common/common.inferface';

type Props={
    userAgent:UserAgentType;
}

const PcBottomTrendBox = ({userAgent}:Props) => {
  return (
    <div className="pc-bottom-trend-box">
      <div className="dot-line-box">
        <div className="sm-circle" />
        <div className="dot-line" />
      </div>
      <div className="circle-wrapper">
        <img
          className="line"
          src={`/assets/mainsection4/${userAgent}/bottomline.png`}
          srcSet={`
                    /assets/mainsection4/${userAgent}/bottomline@2x.png 2x,
                    /assets/mainsection4/${userAgent}/bottomline@3x.png 3x,
                    `}
          alt="bottom-line"
        />
        {circleArr.map(({ text }, idx) => (
          <Circle text={text} key={idx} color="#01A0A0" />
        ))}
      </div>
    </div>
  );
}

export default PcBottomTrendBox;

const circleArr = [
  {
    text: "마케팅",
  },
  {
    text: "회원",
  },
  {
    text: `스마트
스토어`,
  },
];