import React from "react";
import PosType from "../../components/PosType/PosType";
import "./css/index.css";
import { UserAgentType } from "../../interface/common/common.inferface";
import Technology from "../../components/Technology/Technology";
import Bottom from "../../components/Bottom/Bottom";
import { ReactComponent as Storesvg } from "./svg/store.svg";
import { ReactComponent as DeviceSvg } from "./svg/device.svg";

import { Link } from "react-router-dom";
import UseLinkLog from "../../hooks/UseLinkLog";
import FooterContainer from "../../components/Footer/container/FooterContainer";
type Props = {
  userAgent: UserAgentType;
};

const CardTerminal = ({ userAgent }: Props) => {
  const { UpdateLinkLog } = UseLinkLog();
  return (
    <main className="card-terminal-page">
      <PosType
        colorText="카드단말기"
        href="https://smartstore.naver.com/shpos/category/1730b07eaad3452e8c55f4acf984aad2?cp=1"
        mainText={
          <>
            카드 단말기 구매하고,
            <br />
            포스프로그램 무료로
            <br />
            다운받으세요
          </>
        }
        src="/assets/cardterminal/cardterminal.png"
        srcSet="/assets/cardterminal/cardterminal@2x.png 2x, /assets/cardterminal/cardterminal@3x.png 3x"
        // type="button"
        buttonText="카드 단말기 구매하기"
      />
      <section className="store-pos-section">
        <div className="store-pos-wrapper">
          <div className="pos-introduction">
            <div className="introduction-text">
              <p className="color-text">간편한 패키지 상품, 매장포스</p>
              <p className="title-text">
                {`복잡한 매장 포스 간편하게
                  패키지 상품으로 준비하세요
                `}
              </p>
              <p className="sub-text">
                {userAgent === "pc"
                  ? `최신형 포스기, OS : Win 10
                  함께 제공되는 부가장비 : 영수증 프린터, 핸드 바코드 스캐너, 돈 박스, 서명 패드
                  포스 프로그램 무료 셋팅, 카드 가맹점 가입 무료
                  옵션 : 고객용 듀얼 모니터`
                  : `최신형 포스기, OS : Win 10
                    함께 제공되는 부가장비 : 영수증 프린터, 
                    핸드 바코드 스캐너, 돈 박스, 서명 패드
                    포스 프로그램 무료 셋팅, 카드 가맹점 가입 무료
                    옵션 : 고객용 듀얼 모니터`}
              </p>
            </div>
            <div className="store">
              <div className="price">
                <span>국내 최저가</span> 600,000 원
              </div>
              <a
                href="https://www.shpos.co.kr/product/2f64dc2f-7c50-4912-9267-ec079f0385d3-62"
                className="naver-store-button"
                target={"_blank"}
                rel="noreferrer"
                onClick={() => {
                  UpdateLinkLog("패키지 상품");
                }}
              >
                네이버 스토어 바로가기
              </a>
            </div>
          </div>
          <div className="pos-img-container">
            <img
              src="/assets/cardterminal/pos.png"
              srcSet="/assets/cardterminal/pos@2x.png 2x, /assets/cardterminal/pos@3x.png 3x"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="terminal-store-section">
        <div className="terminal-store-wrapper">
          {terminalArr1.map((item, idx) => (
            <div className="terminal-choice-container" key={idx}>
              <div className="text-container">
                <div className="logo-img">{item.svg}</div>
                <div className="title-container">
                  <p className="color-text">{item.colorText}</p>
                  <p className="title">{item.title}</p>
                </div>
              </div>
              <div className="terminal-card-container">
                {item.terminalCard.map((item, idx) => (
                  <div className="terminal-card" key={idx}>
                    <img
                      src={item.src}
                      srcSet={item.srcSet}
                      alt={`terminal${idx}`}
                    />
                    <p>{item.text}</p>
                  </div>
                ))}
              </div>
              <a
                href="https://smartstore.naver.com/shpos/category/e7cbef1ec0ea43adbd965116187ab632?cp=1"
                className="store-button"
                target={"_blank"}
                onClick={() => {
                  UpdateLinkLog(item.colorText);
                }}
                rel="noreferrer"
              >
                상품 구매하러 가기
              </a>
            </div>
          ))}
        </div>
      </section>

      <section className="question-section">
        <div className="question-wrapper">
          <div className="text-container">
            <div className="color-text">FAQ</div>
            <div className="title-text">자주 묻는 질문</div>
          </div>
          <div className="question-container">
            <div className="question-card-container">
              {questionArr.map((item, idx) => (
                <div className="question-card" key={idx}>
                  <p className="question">{item.question}</p>
                  <p className="answer">{item.answer}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="customer-inquiry-container">
            <Link to="/user/inquiry" className="customer-inquiry-button">
              <p>더 궁금한 점이 있나요?</p>
              <p>고객문의</p>
            </Link>
          </div>
        </div>
      </section>
      <Technology />
      <Bottom />
      <FooterContainer />
    </main>
  );
};

export default CardTerminal;
const terminalArr1 = [
  {
    colorText: "카드 단말기 구매",
    title: "다양한 카드 단말기를 선택할 수 있습니다",
    svg: <Storesvg />,
    terminalCard: [
      {
        text: "간편 결제 유선단말기",
        src: "/assets/cardterminal/terminal/terminal1.png",
        srcSet:
          "/assets/cardterminal/terminal/terminal1@2x.png 2x, /assets/cardterminal/terminal/terminal1@3x.png 3x",
      },
      {
        text: "애플페이 무선단말기",
        src: "/assets/cardterminal/terminal/terminal2.png",
        srcSet:
          "/assets/cardterminal/terminal/terminal2@2.png 2x, /assets/cardterminal/terminal/terminal2@3.png 3x",
      },
      {
        text: "애플페이 NFC단말기",
        src: "/assets/cardterminal/terminal/terminal3.png",
        srcSet:
          "/assets/cardterminal/terminal/terminal3@2.png 2x, /assets/cardterminal/terminal/terminal3@3.png 3x",
      },
      {
        text: "유선단말기",
        src: "/assets/cardterminal/terminal/terminal4.png",
        srcSet:
          "/assets/cardterminal/terminal/terminal4@2x.png 2x, /assets/cardterminal/terminal/terminal4@3x.png 3x",
      },
      {
        text: "휴대용 무선단말기",
        src: "/assets/cardterminal/terminal/terminal5.png",
        srcSet:
          "/assets/cardterminal/terminal/terminal5@2.png 2x, /assets/cardterminal/terminal/terminal5@3.png 3x",
      },
      {
        text: "QR패드 유선단말기",
        src: "/assets/cardterminal/terminal/terminal6.png",
        srcSet:
          "/assets/cardterminal/terminal/terminal6@2.png 2x, /assets/cardterminal/terminal/terminal6@3.png 3x",
      },
    ],
  },
  {
    colorText: "주변 장치 구매",
    title: "주변 장치와 함께 구매하면 좋아요",
    svg: <DeviceSvg />,
    terminalCard: [
      {
        text: "영수지",
        src: "/assets/cardterminal/device/device1.png",
        srcSet:
          "/assets/cardterminal/device/device1@2.png 2x, /assets/cardterminal/device/device1@3.png 3x",
      },
      {
        text: "금전 보관함",
        src: "/assets/cardterminal/device/device2.png",
        srcSet:
          "/assets/cardterminal/device/device2@2.png 2x, /assets/cardterminal/device/device2@3.png 3x",
      },
      {
        text: "프린터",
        src: "/assets/cardterminal/device/device3.png",
        srcSet:
          "/assets/cardterminal/device/device3@2.png 2x, /assets/cardterminal/device/device3@3.png 3x",
      },
      {
        text: "핸드스캐너",
        src: "/assets/cardterminal/device/device4.png",
        srcSet:
          "/assets/cardterminal/device/device4@2.png 2x, /assets/cardterminal/device/device4@3.png 3x",
      },
    ],
  },
];
const questionArr = [
  {
    question: "Q. 부가 장비는 무료인가요?",
    answer: `네. 패키지 상품 구입시 
부가 장비 무료 제공됩니다`,
  },
  {
    question: "Q. 렌탈도 포스프로그램 무료 인가요?",
    answer: `네. 렌탈도 100세까지 
무료로 사용할 수 있습니다`,
  },
  {
    question: "Q. 렌탈도 가능한가요?",
    answer: `네. 부담없는 가격으로 
사용하실 수 있습니다`,
  },
  {
    question: "Q. 방문 설치 가능한가요?",
    answer: `네. 소정의 출장비가 발생합니다. 
자세한 사항은 고객센터 문의 바랍니다.`,
  },
  {
    question: "Q. 포스프로그램 무료인가요?",
    answer: `네. 포스프로그램도 셋팅되어져 
무료 제공됩니다`,
  },
  {
    question: "Q. A/S는 어떻게 하나요?",
    answer: `고객센터 연락주시면 
원격으로 지원해드립니다`,
  },
];
