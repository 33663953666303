import React from "react";
import "./css/index.css";
type Props = {
  title: string;
  sub?: string;
  type?: "down";
  Active?: () => void;
};

const PosTypeV2 = ({ title, sub, Active, type }: Props) => {
  return (
    <div className="pos-type-v2">
      <img
        src="/assets/postype/left.png"
        srcSet="/assets/postype/left@2x.png 2x, /assets/postype/left@3x.png 3x"
        className="left-img"
        alt=""
      />
      <img
        className="right-img"
        src="/assets/postype/right.png"
        srcSet="/assets/postype/right@2x.png 2x, /assets/postype/right@3x.png 3x"
        alt=""
      />
      <b className="pos-type-v2-title">{title}</b>
      {sub && <p className="pos-type-v2-sub">{sub}</p>}
      {type === "down" && (
        <button className="download-btn" onClick={Active}>
          <img src="/assets/download/down.svg" alt="down" />
          <p className="download-btn-title">SKPOS 다운로드</p>
        </button>
      )}
    </div>
  );
};

export default PosTypeV2;
