import React from "react";
import "./css/index.css";
import { UserAgentType } from "../../interface/common/common.inferface";
type Props = {
  title: string;
  sub: any;
  src: string;
};
const ContentImg = ({ title, sub, src }: Props) => {
  return (
    <div className="content-img">
      <div className="text-wrapper">
        <p className="title">{title}</p>
        <p className="subtitle">{sub}</p>
      </div>
      <img className="img" src={`/assets/main/svg/${src}.svg`} alt={`${src}`} />
    </div>
  );
};

export default ContentImg;
