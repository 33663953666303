import React, { useEffect } from 'react'
import UserLoading from '../UserLoading'
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import { useNavigate } from 'react-router-dom';
type Props = {
  uid:string | undefined
};
const UserLoadingContainer = ({ uid }: Props) => {
    const navigate = useNavigate();
  useEffect(() => {
    if (uid) {
      getDocs(
        query(collection(db, "inquiry"), where("uid", "==", uid))
      ).then((docs) => {
        if (!docs.empty) {
          return navigate("/user/inquiry");
        }else{
            return navigate('/user/write')
        }
      });
    }
  }, [uid, navigate]);
  return <UserLoading />;
};

export default UserLoadingContainer