import React from "react";
import "./css/index.css";
import AlertTitle from "../../../../components/AlertTitle/AlertTitle";
import { UserAgentType } from "../../../../interface/common/common.inferface";
import useIntersectionObserver from "../../../../hooks/UseIntersectionObserver";
type Props = {
  userAgent: UserAgentType;
};
const Section02 = ({ userAgent }: Props) => {
    const ref = useIntersectionObserver(0.5);
  const imgArr = [
    {
      title: "행사 전단부터",
      src: "section02",
    },
    {
      title: "",
      src: "section02-2",
    },
    {
      title: "제품 구매까지",
      src: "section02-3",
    },
  ];
  return (
    <section ref={ref} className="alert-section02 hidden-section">
      <div className="section02-wrapper">
        <AlertTitle
          title={`알림톡으로 하는
우리동네상권 마케팅`}
          subtitle={`알림톡으로 전단을 바로 확인하고
구매/결제까지 한번에!`}
        />
        <div className="img-wrapper">
          {imgArr.map(({ title, src }, idx) => (
            <div className="img-card" key={idx}>
              <p className="img-card-title">{title}</p>
              <img
                className="img-card-img"
                src={`/assets/alerttalk/section02/${userAgent}/${src}.png`}
                srcSet={`
                    /assets/alerttalk/section02/${userAgent}/${src}@2x.png 2x,
                    /assets/alerttalk/section02/${userAgent}/${src}@3x.png 3x,
                    `}
                alt="section02"
              />
            </div>
          ))}
        </div>
      </div>
      <img
        className="gradient"
        src={`/assets/alerttalk/section02/${userAgent}/gradient.png`}
        srcSet={`
/assets/alerttalk/section02/${userAgent}/gradient@2x.png 2x,
/assets/alerttalk/section02/${userAgent}/gradient@3x.png 3x,
          `}
        alt="gradient"
      />
    </section>
  );
};

export default Section02;
