import React from 'react'
import "./css/index.css";
import { UserAgentType } from '../../interface/common/common.inferface';

type Props = {
  src: string;
  userAgent: UserAgentType;
}
const AlertCircle = ({ src, userAgent }: Props) => {
  return (
    <div className="alert-circle">
      <img
        className="img"
        src={`/assets/alerttalk/section05/${userAgent}/${src}.png`}
        srcSet={`
            /assets/alerttalk/section05/${userAgent}/${src}@2x.png 2x,
            /assets/alerttalk/section05/${userAgent}/${src}@3x.png 3x
        `}
        alt=""
      />
    </div>
  );
}

export default AlertCircle