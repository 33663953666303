import React, { Fragment } from "react";
import PosType from "../../components/PosType/PosType";
import "./css/index.css";
import Technology from "../../components/Technology/Technology";
import Bottom from "../../components/Bottom/Bottom";
import { ReactComponent as ArrowSvg } from "../Download/svg/arrow.svg";
import { ReactComponent as StoreSvg } from "./svg/store.svg";
import useIntersectionObserver from "../../hooks/UseIntersectionObserver";
import FooterContainer from "../../components/Footer/container/FooterContainer";
type Props = {};

const Procedure = (props: Props) => {
  const ref1 = useIntersectionObserver(0.5);
  const ref2 = useIntersectionObserver(0.5);

  return (
    <main className="procedure-page">
      <PosType
        colorText="가맹절차"
        mainText={
          <>
            모든 카드사 가맹점 <br />
            가입 무료 지원
          </>
        }
        subText=""
        src="/assets/procedure/mainimg.png"
        srcSet="/assets/procedure/mainimg@2x.png 2x, /assets/procedure/mainimg@3x.png 3x"
      />
      <section ref={ref1} className="card-company-section hidden-section">
        <div className="card-company-wrapper">
          <div className="title-wrapper">
            <div className="circle">
              <img src="/assets/procedure/cardcompany.svg" alt="" />
            </div>
            <div className="title-container">
              <p className="title">
                {`모든 카드사 가맹은
무료로 지원해 드립니다`}
              </p>
            </div>
          </div>
          <div className="card-container">
            {cardArr.map((item, idx) => (
              <Fragment key={idx}>
                <div className="card">
                  <div
                    className={`step-box ${
                      idx === 0 ? "step1" : idx === 1 ? "step2" : "step3"
                    }`}
                  >
                    <div className="step-number">{item.step}</div>
                    <div className="step-text">{item.title}</div>
                  </div>
                  <div className="sub-title">{item.subtitle}</div>
                </div>
                {idx !== cardArr.length - 1 && <ArrowSvg className="arrow" />}
              </Fragment>
            ))}
          </div>
          {/* <a href="" className="kakaotalk-button">
            카카오톡 상담 바로가기
          </a> */}
        </div>
      </section>

      <section ref={ref2} className="store-program-section hidden-section">
        <div className="store-program-wrapper">
          <div className="title-container">
            <div className="circle">
              <StoreSvg />
            </div>
            <div className="title">
              {`가맹점이 아니여도
프로그램 다운로드 할 수 있나요?`}
            </div>
            <div className="bubble">
              <p>
                네. 가맹점이 아니여도{" "}
                <span className="color">다운로드가 가능합니다</span>
              </p>
              <p>프로그램 선 사용 후 단말기를 구입하셔도 됩니다</p>
            </div>
          </div>
          <div className="img-container">
            <img
              className="pos-img"
              src="/assets/common/poscapture.png"
              srcSet="/assets/common/poscapture@2x.png 2x, /assets/common/poscapture@3x.png 3x"
              alt=""
            />
            {/* <img
              className="cloud-img"
              src="/assets/procedure/cloud.png"
              srcSet="/assets/procedure/cloud@2.png 2x, /assets/procedure/cloud@3.png 3x"
              alt=""
            /> */}
          </div>
        </div>
      </section>
      <Technology />
      <Bottom />
      <FooterContainer />
    </main>
  );
};

export default Procedure;

const cardArr = [
  {
    step: "STEP 01",
    title: `카드 단말기
구입 및 결제`,
    subtitle: (
      <>
        상담원이 확인 후 <br />
        카카오톡으로 <br /> 필요한 서류들을 <br />
        안내합니다
      </>
    ),
  },
  {
    step: "STEP 02",
    title: `필요한
서류 제출`,
    subtitle: (
      <>
        가맹점 가입에 필요한 <br /> 서류 제출
      </>
    ),
  },
  {
    step: "STEP 03",
    title: `가맹점 가입
및 발송`,
    subtitle: (
      <>
        가맹점 가입 완료 후 발송
        <br /> 패키지 상품은
        <br /> 포스프로그램 <br /> 설치
        <br /> 발송됩니다
      </>
    ),
  },
];
