import React, { useCallback, useEffect, useState } from "react";
import Header from "../Header";
import { UserAgentType } from "../../../interface/common/common.inferface";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../../reducer";
import { auth, db } from "../../../config/firebase";
import { AuthType, ResetAuth, setAuthState } from "../../../reducer/auth";
import { doc, getDoc } from "firebase/firestore";
import { PopupChange } from "../../../reducer/config";
import { signOut } from "firebase/auth";
type Props = {
  userAgent: UserAgentType;
};
const HeaderContainer = ({ userAgent }: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isInquiryOpen, setIsInquiryOpen] = useState<boolean>(false);
  const isLogin = useSelector<RootState, string | undefined>(
    (state) => state.auth.uid
  );

  const [isToggleOpen, setIsToggleOpen] = useState<boolean>(false);
  const [activeText, setActiveText] = useState<number | null>(null);
  const ToggleOpenClick = () => {
    setIsToggleOpen((prev) => !prev);
  };
  const MenuNavigate = (idx: number, link: string) => {
    setActiveText(idx);
    if (idx === 3) {
      setIsInquiryOpen((prev) => !prev);
    } else {
      navigate(link);
      setIsInquiryOpen(false);
    }
  };
  const LoginBtnClicked = useCallback(() => {
    dispatch(
      PopupChange({
        active: true,
        type: "login",
      })
    );
  }, [dispatch]);
  const LogOutBtn = useCallback(() => {
    signOut(auth);
  }, [auth]);

  useEffect(() => {
    document.getElementById("root")?.scrollTo(0, 0);
    setIsToggleOpen(false);
    return () => {};
  }, [location]);
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user && user.uid) {
        const userData = await getDoc(doc(db, "user", user.uid));
        if (userData.exists()) {
          const data = userData.data() as AuthType;
          dispatch(setAuthState(data));
        } else {
          dispatch(ResetAuth());
        }
      } else {
        dispatch(ResetAuth());
      }
    });

    return () => {};
  }, []);
  return (
    <Header
      userAgent={userAgent}
      isToggleOpen={isToggleOpen}
      activeText={activeText}
      isInquiryOpen={isInquiryOpen}
      isLogin={isLogin}
      LogOutBtn={LogOutBtn}
      LoginBtnClicked={LoginBtnClicked}
      MenuNavigate={MenuNavigate}
      ToggleOpenClick={ToggleOpenClick}
    />
  );
};

export default HeaderContainer;
