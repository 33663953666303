import React from 'react'
import "./css/index.css";
import { UserAgentType } from '../../interface/common/common.inferface';
type Props = {
  img?: string;
  title: string;
  subTitle: string;
  type: "img" | "noimg";
  userAgent: UserAgentType;
};
const SelectorCard = ({
  img,
  title,
  subTitle,
  type,
  userAgent,
}: Props) => {
  return (
    <div
      className={`selector-card`}
    >
      {type === "img" ? (
        <div className="img-box">
          <img
            src={`/assets/mainsection3/${userAgent}/${img}.png`}
            srcSet={`/assets/mainsection3/${userAgent}/${img}@2x.png 2x,
                /assets/mainsection3/${userAgent}/${img}@3x.png 3x,
            `}
            alt={img}
          />
          <p className="title">{title}</p>
          <p className="sub-title">{subTitle}</p>
        </div>
      ) : (
        <div className="no-img-box">
          <p className="title">{title}</p>
          <p className="sub-title">{subTitle}</p>
        </div>
      )}
    </div>
  );
};

export default SelectorCard;