import React from 'react'
import "./css/index.css";
import { UserAgentType } from '../../interface/common/common.inferface';

type Props={
    src:string;
    userAgent:UserAgentType;
    text:string;
}
const Payment = ({src,userAgent,text}:Props) => {
  return (
    <div className="pay-card">
      <img
        className={src}
        src={`/assets/pay/${userAgent}/${src}.png`}
        srcSet={`
                /assets/pay/${userAgent}/${src}@2x.png 2x,
                /assets/pay/${userAgent}/${src}@3x.png 3x,
                `}
        alt={src}
      />
      <p className="pay-text">{text}</p>
    </div>
  );
}

export default Payment