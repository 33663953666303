import React from "react";
import "./css/index.css";
import Bubble from "../Bubble/Bubble";
import { UserAgentType } from "../../interface/common/common.inferface";

type Props = {
  userAgent: UserAgentType;
};

const PcMiddleTrendBox = ({ userAgent }: Props) => {
  return (
    <div className="pc-middle-trend-box">
      <div className="bubble-box">
        <Bubble
          title="결제 시스템 그 이상"
          subTitle={`매장관리 솔루션을 벗어나 매출 상승을 위한
고객관리와 마케팅 방안 제공`}
          className="left-bubble"
        />
        <Bubble
          title="모바일 마케팅"
          subTitle={`포스시스템과 마케팅 플랫폼의
연동으로 필터링 된 고객에게 타켓 마케팅`}
          className="left-bubble"
        />
      </div>
      <div className="skpos-logo-wrapper">
        <img
          className="top-line"
          src={`/assets/mainsection4/${userAgent}/top-line.png`}
          srcSet={`
                    /assets/mainsection4/${userAgent}/top-line@2x.png 2x,
                    /assets/mainsection4/${userAgent}/top-line@3x.png 3x,
                  `}
          alt="top-line"
        />
        <div className="skpos-logo">
          <img
            src={`/assets/mainsection4/${userAgent}/skpos-logo.png`}
            srcSet={`
                    /assets/mainsection4/${userAgent}/skpos-logo@2x.png 2x,
                    /assets/mainsection4/${userAgent}/skpos-logo@3x.png 3x,
                  `}
            alt="skpos-logo"
          />
        </div>
        <img
          src={`/assets/mainsection4/${userAgent}/bottom-line.png`}
          srcSet={`
                    /assets/mainsection4/${userAgent}/bottom-line@2x.png 2x,
                    /assets/mainsection4/${userAgent}/bottom-line@3x.png 3x,
                  `}
          alt="bottom-line"
          className="bottom-line"
        />
      </div>
      <div className="bubble-box">
        <Bubble
          title="통합관리"
          subTitle={`온,오프라인 통합 관리 서비스로 상품, 매출,
재고, 회원에 대한 체계적인 관리 지원`}
          className="right-bubble"
        />
        <Bubble
          title="스마트스토어(베타)"
          subTitle={`네이버 스마트스토어 매출 자료를
SKPOS로 통합 관리`}
          className="right-bubble"
        />
      </div>
    </div>
  );
};

export default PcMiddleTrendBox;
