import React from 'react'
import "./css/index.css";
import { UserAgentType } from '../../../../interface/common/common.inferface';
import AlertTitle from '../../../../components/AlertTitle/AlertTitle';
import useIntersectionObserver from '../../../../hooks/UseIntersectionObserver';
type Props = {
  userAgent: UserAgentType;
}
const Section06 = ({ userAgent }: Props) => {
      const ref = useIntersectionObserver(0.3);

  const arr = [
    {
      src: "message",
      title: `간편하게 알림톡을 작성하고
고객에게 전송해보세요`,
      subtitle: `알림톡을 간편하게 작성하고
보유 고객들에게 일괄 전송할 수 있습니다.`,
      img: "section06-1",
    },
    {
      src: "leaflet",
      title: `입력만으로 간편하게 만드는
스마트 전단지`,
      subtitle: `무료로 제공되는 5만개 이상의 상품 이미지와
내용 입력만으로 간편하게 만드는 스마트 전단지`,
      img: "section06-2",
    },
    {
      src: "leaflet",
      title: `쿠폰을 간단하게 만들고
고객에게 발송해보세요`,
      subtitle: `내용을 입력해서 만드는 간편 쿠폰 만들기 부터
쿠폰을 보유 고객에게 발급까지`,
      img: "section06-3",
    },
  ];
  return (
    <section ref={ref} className="alert-section06 hidden-section">
      <div className="section06-wrapper">
        {arr.map(({ src, title, subtitle, img }, idx) => (
          <div className="card">
            <img className='sub-img' src={`/assets/alerttalk/section06/${src}.svg`} />
            <AlertTitle title={title} subtitle={subtitle} />
            <img
              className='main-img'
              src={`/assets/alerttalk/section06/${userAgent}/${img}.png`}
              srcSet={`
                /assets/alerttalk/section06/${userAgent}/${img}@2x.png 2x,
                /assets/alerttalk/section06/${userAgent}/${img}@3x.png 3x,
            `}
              alt="img"
            />
          </div>
        ))}
      </div>
    </section>
  );
}

export default Section06