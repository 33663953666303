import React from 'react'
import "./css/index.css";
import Bubble from '../Bubble/Bubble';

import { UserAgentType } from '../../interface/common/common.inferface';
import Circle from '../Circle/Circle';


type Props={
    userAgent:UserAgentType;

}
const TrendTopBox = ({userAgent}:Props) => {
  return (
    <div className="trend-top-box">
      <Bubble
        title="결제 시스템 그 이상"
        subTitle={
          userAgent !== "mb"
            ? `매장관리 솔루션을 벗어나 매출 상승을 위한
고객관리와 마케팅 방안 제공`
            : `매장관리 솔루션을 벗어나
매출 상승을 위한
고객관리와 마케팅 방안 제공`
        }
        className=""
      />
      <div className="circle-wrapper">
        <img
          className="line"
          src={`/assets/mainsection4/${userAgent}/topline.png`}
          srcSet={`
                    /assets/mainsection4/${userAgent}/topline@2x.png 2x,
                    /assets/mainsection4/${userAgent}/topline@3x.png 3x,
                    `}
        />
        {circleArr.map(({ text }, idx) => (
          <Circle text={text} key={idx} />
        ))}
      </div>
      <div className="dot-line-box top-dot-line">
        <div className="dot-line " />
        <div className="sm-circle" />
      </div>
    </div>
  );
}

export default TrendTopBox;

const circleArr = [
  {
    text: `오프라인
매출`,
  },
  {
    text: `상품`,
  },
  {
    text: `온라인
주문`,
  },
];