import React, { forwardRef, useRef } from "react";
import "./css/index.css";
import SelectorCard from "../../SelectorCard/SelectorCard";
import { UserAgentType } from "../../../interface/common/common.inferface";
import useIntersectionObserver from "../../../hooks/UseIntersectionObserver";

interface MainSection3Refs {
  ref1: HTMLDivElement | null;
  ref2: HTMLDivElement | null;
}
type Props = {
  menuArr: {
    title: string;
    svg: any;
  }[];
  industryArr: {
    title: string;
    img: string;
    subTitle: string;
  }[];
  memberArr: {
    title: string;
    subTitle: string;
  }[];
  easyArr: {
    img: string;
    title: string;
    subTitle: string;
  }[];
  userAgent: UserAgentType;
  crtIdx: number;
  MenuSelector: (idx: number) => void;
};
const MainSection3 = forwardRef<HTMLDivElement, Props>(
  (
    {
      menuArr,
      industryArr,
      memberArr,
      easyArr,
      userAgent,
      crtIdx,
      MenuSelector,
    },
    ref
  ) => {
    const ref2 = useIntersectionObserver(0.4);
    const ref3 = useIntersectionObserver(0.5);

    return (
      <section className="main-section3  ">
        <div className="content-wrapper hidden-section" ref={ref}>
          <p className="main-content">사장님의 현명한 선택</p>
          <p className="sub-content">
            {userAgent !== "mb"
              ? `도소매 사장님들의 현명한 선택
업종 최적화된 서비스로 업무 효율을 높이세요`
              : `도소매 사장님들의 가장 현명한 선택
업종 최적화된 서비스로 
업무 효율을 높이세요`}
          </p>
        </div>
        <div className="selector-menu hidden-section" ref={ref2}>
          {menuArr.map(({ title, svg }, idx) => (
            <button
              className={`selector-btn ${crtIdx === idx && "color-btn"}`}
              key={idx}
              style={{
                backgroundColor: crtIdx === idx ? "#00606C" : "",
              }}
              onClick={() => MenuSelector(idx)}
            >
              {svg}
              <p
                className="text"
                style={{ color: crtIdx === idx ? "white" : "" }}
              >
                {title}
              </p>
            </button>
          ))}
        </div>
        <div
          className={`selector-card-box hidden-section`}
          id="scroll-section"
          ref={ref3}
        >
          {crtIdx === 0 &&
            industryArr.map(({ title, img, subTitle }, idx) =>
              userAgent === "mb" ? (
                <SelectorCard
                  title={title}
                  img={img}
                  subTitle={subTitle}
                  userAgent={userAgent}
                  type="img"
                  key={idx}
                />
              ) : (
                <SelectorCard
                  title={title}
                  img={img}
                  subTitle={subTitle}
                  userAgent={userAgent}
                  type="img"
                  key={idx}
                />
              )
            )}
          {crtIdx === 1 &&
            memberArr.map(({ title, subTitle }, idx) =>
              userAgent === "mb" ? (
                <SelectorCard
                  title={title}
                  subTitle={subTitle}
                  userAgent={userAgent}
                  type="noimg"
                  key={idx}
                />
              ) : (
                <SelectorCard
                  title={title}
                  subTitle={subTitle}
                  userAgent={userAgent}
                  type="noimg"
                  key={idx}
                />
              )
            )}
          {crtIdx === 2 &&
            easyArr.map(({ title, img, subTitle }, idx) =>
              userAgent === "mb" ? (
                <SelectorCard
                  title={title}
                  img={img}
                  subTitle={subTitle}
                  userAgent={userAgent}
                  type="img"
                  key={idx}
                />
              ) : (
                <SelectorCard
                  title={title}
                  img={img}
                  subTitle={subTitle}
                  userAgent={userAgent}
                  type="img"
                  key={idx}
                />
              )
            )}
        </div>
      </section>
    );
  }
);

export default MainSection3;
