import "./css/index.css";
import { UserAgentType } from "../../interface/common/common.inferface";
type Props = {
  currentIndex: number;
  images: string[];
  userAgent: UserAgentType;
};

const MainBanner = ({ currentIndex, images, userAgent }: Props) => {
  return (
    <section className="main-banner-section">
      <div className="main-img-wrapper">
        {images.map((image, idx) => (
          <img
            key={idx}
            src={`/assets/main/${userAgent}/${image}.png`}
            srcSet={`
                /assets/main/${userAgent}/${image}@2x.png 2x,
                /assets/main/${userAgent}/${image}@3x.png 3x,
                `}
            alt={`Slide ${idx + 1}`}
            className={`slide ${currentIndex === idx && "active"}`}
          />
        ))}
      </div>
      <div className="content-wrapper">
        <p className="sub-text">{`마케팅이 접목된
도소매 전문 포스 프로그램`}</p>
        <p className="main-content">{`SKPOS가 만들어가는
포스 솔루션의 새로운
트렌드를 경험해 보세요`}</p>
        <div className="store-img-wrapper">
          <a
            href="https://play.google.com/store/apps/details?id=com.skpos"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`/assets/main/${userAgent}/google.png`}
              srcSet={`/assets/main/${userAgent}/google@2x.png 2x,
                        /assets/main/${userAgent}/google@3x.png 3x,
                    `}
              alt="google-play"
              className="google"
            />
          </a>
          <a
            href="https://apps.apple.com/kr/app/skpos/id6740397394"
            target="_blank"
            className="apple"
            rel="noopener noreferrer"
          >
            <img
              src={`/assets/main/${userAgent}/apple.png`}
              srcSet={`/assets/main/${userAgent}/apple@2x.png 2x,
                        /assets/main/${userAgent}/apple@3x.png 3x,
                    `}
              alt="app-store"
              className="apple"
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default MainBanner;
