import React, { useCallback, useState } from "react";
import { UserAgentType } from "../../../../interface/common/common.inferface";
import MainSection3 from "../MainSection3";
import { ReactComponent as IndustrySvg } from "../svg/industry.svg";
import { ReactComponent as MemberSvg } from "../svg/member.svg";
import { ReactComponent as EasySvg } from "../svg/easy.svg";
import useIntersectionObserver from "../../../../hooks/UseIntersectionObserver";
type Props = {
  userAgent: UserAgentType;
};

const MainSection3Container = ({ userAgent }: Props) => {
  const ref = useIntersectionObserver(0.3);
  const [crtIdx, setCrtIdx] = useState<number>(0);

  const MenuSelector = useCallback(
    (idx: number) => {
      setCrtIdx(idx);
      document.getElementById("scroll-section")?.scrollTo(0, 0);
    },
    [crtIdx]
  );
  return (
    <MainSection3
      menuArr={menuArr}
      ref={ref}
      industryArr={industryArr}
      memberArr={memberArr}
      easyArr={easyArr}
      crtIdx={crtIdx}
      userAgent={userAgent}
      MenuSelector={MenuSelector}
    />
  );
};

export default MainSection3Container;

const menuArr = [
  {
    title: "업종 최적화",
    svg: <IndustrySvg />,
  },
  {
    title: "회원 관리",
    svg: <MemberSvg />,
  },
  {
    title: "쉬운 사용",
    svg: <EasySvg />,
  },
];

const industryArr = [
  {
    img: "industry1",
    title: `상품 매입, 대금결제와 
이력조회, 엑셀 상품 
등록까지! 도소매 매장에 
필요한 기능은 
다 있어요.`,
    subTitle: `도매 | 영어조합법인 올레바당`,
  },
  {
    img: "industry2",
    title: `상품재고를 
실시간으로
확인 할 수 있어, 
재고관리가
너무 편해 졌어요.`,
    subTitle: `낚시매장 | 생활낚시닷컴`,
  },
  {
    img: "industry3",
    title: `저울 상품 판매와
바코드 출력은
우리 매장에 
꼭 필요한
기능입니다.`,
    subTitle: `축산/정육 | 청주한우`,
  },
  {
    img: "industry4",
    title: `구매 상품이 많아도,  
손님이
많이 몰려도 
빠르게 결제할 수 있어 
정말 좋아요.`,
    subTitle: `식자재 | 복푸드`,
  },
];

const memberArr = [
  {
    title: `상품별
포인트 적립`,
    subTitle: `각 상품별로
포인트 적립
유무 설정`,
  },
  {
    title: `결제 종류별
포인트 적립`,
    subTitle: `현금 결제, 카드 결제
구분하여 포인트
적립률 설정`,
  },
  {
    title: `적립
제외`,
    subTitle: `포인트로 결제 된 전표,
할인 적용된 상품은
포인트 적립에서
제외 가능`,
  },
  {
    title: `판매에서 
회원 등록`,
    subTitle: `전화번호
뒤 4자리로
회원 등록, 검색`,
  },
];

const easyArr = [
  {
    img: "easy1",
    title: `단어만 봐도 바로 
알 수 있을 정도로
직관적이에요.불필요한
단계 없는 간결한
결제는 정말 편해요.`,
    subTitle: `도매 | C-STAR`,
  },
  {
    img: "easy2",
    title: `번거로운 화면 
이동없이 판매에서 
신상품 등록,상품검색,
카테고리 관리, 판매가를
변경 할 수 있어요.`,
    subTitle: `약국 | 한마음 약국`,
  },
  {
    img: "easy3",
    title: `도소매 프로그램을 
사용하는 이유!
바코드 자동생성, 
시가판매등 사용해 보면
진가를 알 수 있어요.`,
    subTitle: `슈퍼 | 싱싱마켓`,
  },
];
