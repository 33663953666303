import React from 'react'
import "./css/index.css";
import { Link } from 'react-router-dom';

const InquiryToggle = () => {
  return (
    <div className='inquiry-toggle-wrapper'>
        {inquiryArr.map(({link,title},idx)=>(
            <Link to={link} className='inquiry-toggle-list' key={idx}>
                {title}
            </Link>
        ))}
    </div>
  )
}

export default InquiryToggle;

const inquiryArr = [
  {
    title: "상담문의",
    link: "/user/inquiry",
  },
  {
    title: "사업 제휴 문의",
    link: "/counsel/business",
  },
];