import React, { useCallback, useEffect, useState } from "react";
import PcHeader from "../PcHeader";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducer";
import { useNavigate } from "react-router-dom";
type Props = {
  headerLists: {
    title: string;
    link: string;
  }[];
  LogOutBtn: () => void;
  LoginBtnClicked: () => void;
};
const PcHeaderContainer = ({
  headerLists,
  LogOutBtn,
  LoginBtnClicked,
}: Props) => {
  const isLogin = useSelector<RootState, string | undefined>(
    (state) => state.auth.uid
  );
  const [isHover, setIsHover] = useState<boolean>(false);
  const MouseHover = useCallback(() => {
    setIsHover(true);
  }, [isHover]);
  const MouseHoverOut = useCallback(() => {
    setIsHover(false);
  }, [isHover]);
 
  return (
    <PcHeader
      isHover={isHover}
      isLogin={isLogin}
      headerList={headerLists}
      LogOutBtn={LogOutBtn}
      LoginBtnClicked={LoginBtnClicked}
      MouseHoverOut={MouseHoverOut}
      MouseHover={MouseHover}
    />
  );
};

export default PcHeaderContainer;
