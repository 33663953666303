import React from "react";
import "./css/index.css";
import AlertTitle from "../../../../components/AlertTitle/AlertTitle";
import useIntersectionObserver from "../../../../hooks/UseIntersectionObserver";
const Section03 = () => {
    const ref = useIntersectionObserver(0.3);

  return (
    <section ref={ref} className="alert-section03 hidden-section">
      <div className="section03-wrapper">
        <AlertTitle
          title={`별도 비용 없이
알림톡 발송 비용 10원`}
          subtitle={`알림톡 발송 비용 10원을 제외하고
별도 비용 없이 사용할 수 있습니다.`}
        />
        <img
          src="/assets/alerttalk/section03/section03.png"
          srcSet="/assets/alerttalk/section03/section03@2x.png 2x,
            /assets/alerttalk/section03/section03@3x.png 3x
            "
          alt=""
        />
      </div>
    </section>
  );
};

export default Section03;
