import React from "react";
import "./css/index.css";
import { UserAgentType } from "../../interface/common/common.inferface";
import useIntersectionObserver from "../../hooks/UseIntersectionObserver";
type Props = {
  userAgent: UserAgentType;
  img: string;
  title: string;
  sub?: string;
  subimg?: boolean;
};
const MainSection10Card = ({ userAgent, img, title, sub, subimg }: Props) => {
  const targetRef = useIntersectionObserver(0.1);
  return (
    <div className="main-section10-card hidden-section" ref={targetRef}>
      <img
        src={`/assets/mainsection10/${userAgent}/${img}.png`}
        srcSet={`/assets/mainsection10/${userAgent}/${img}@2x.png 2x, /assets/mainsection10/${userAgent}/${img}@3x.png 3x`}
        alt=""
      />
      <div className="content-text">
        {subimg && (
          <img
            className="excel"
            src={`/assets/mainsection10/excel.png`}
            srcSet={`
                /assets/mainsection10/excel@2x.png 2x,
                /assets/mainsection10/excel@3x.png 3x,
            `}
          />
        )}
        <p className="text">{title}</p>
        <p className="sub-text">{sub}</p>
      </div>
    </div>
  );
};

export default MainSection10Card;
