import React from 'react'
import "./css/index.css";
import InquiryToggle from '../InquiryToggle/InquiryToggle';

type Props = {
  menuArr: {
    title: string;
    link: string;
  }[];
  activeText:number | null;
  isInquiryOpen: boolean;
  ToggleOpenClick: () => void;
  MenuNavigate: (idx: number, link: string) => void;
};

const HeaderToggle = ({
  menuArr,
  isInquiryOpen,
  activeText,
  ToggleOpenClick,
  MenuNavigate,
}: Props) => {
  return (
    <div className="toggle-open-container">
      <div className="cancel-container">
        <img src="/assets/cancel.svg" alt="cancel" onClick={ToggleOpenClick} />
      </div>
      <div className="mb-menulist-wrapper">
        <ul className="mb-menulist-container">
          {menuArr.map(({ title, link }, idx) => (
            <li
              key={idx}
              className={`lists ${idx === activeText ? "active-lists" : ""}`}
              onClick={() => MenuNavigate(idx, link)}
            >
              {title}
            </li>
          ))}
          <div
            className={`inquiry-container ${isInquiryOpen ? "open" : "closed"}`}
          >
            <InquiryToggle />
          </div>
        </ul>
      </div>
    </div>
  );
};

export default HeaderToggle;