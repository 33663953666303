import React from 'react'
import "./css/index.css";
import { UserAgentType } from '../../../../interface/common/common.inferface';
import AlertTitle from '../../../../components/AlertTitle/AlertTitle';
import AlertCircle from '../../../../components/AlertCircle/AlertCircle';
import useIntersectionObserver from '../../../../hooks/UseIntersectionObserver';
type Props = {
  userAgent: UserAgentType;
}
const Section06 = ({ userAgent }: Props) => {
      const ref = useIntersectionObserver(0.3);

  return (
    <section ref={ref} className="alert-section05 hidden-section">
      <div className="section05-wrapper">
        <AlertTitle
          title={`SKPOS와 연동되어
매출통합관리`}
          subtitle={`알림톡 서비스는 SKPOS와 연동되어
매출을 통합관리할 수 있습니다.`}
        />
        <div className="img-menual-wrapper">
          <AlertCircle src={"section05-1"} userAgent={userAgent} />
          <img
            className='change-img'
            src={`/assets/alerttalk/section05/${userAgent}/change.png`}
            srcSet={`
              /assets/alerttalk/section05/${userAgent}/change@2x.png 2x, 
              /assets/alerttalk/section05/${userAgent}/change@3x.png 3x
              `}
            alt=""
          />
          <AlertCircle src={"section05-2"} userAgent={userAgent} />
        </div>
      </div>
    </section>
  );
}

export default Section06