import React from "react";
import "./css/index.css";
import useIntersectionObserver from "../../hooks/UseIntersectionObserver";
import { UserAgentType } from "../../interface/common/common.inferface";
type Props = {
  userAgent: UserAgentType;
  src: string;
  title: string;
  sub: string;
};
const MainSection8Card = ({ userAgent, src, title, sub }: Props) => {
  const targetRef = useIntersectionObserver(0.1);
  return (
    <div className="main-section8-card hidden-section" ref={targetRef}>
      <div className="card">
        <img
          src={`/assets/mainsection8/${userAgent}/${src}.png`}
          srcSet={`
                /assets/mainsection8/${userAgent}/${src}@2x.png 2x, 
                /assets/mainsection8/${userAgent}/${src}@3x.png 3x,
            `}
        />
      </div>
      <div className="content-text">
        <p className="title">{title}</p>
        <p className="sub-text">{sub}</p>
      </div>
    </div>
  );
};

export default MainSection8Card;
