import React, { useEffect, useState } from "react";
import { UserAgentType } from "../../../../interface/common/common.inferface";
import MainSection2 from "../MainSection2";

type Props = {
  userAgent: UserAgentType;
};

const MainSection2Container = ({ userAgent }: Props) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % contentArr.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);
  return (
    <MainSection2
      userAgent={userAgent}
      contentArr={contentArr}
      currentIndex={currentIndex}
    />
  );
};

export default MainSection2Container;

const contentArr = [
  {
    title: "기능 만족도",
    sub: "A ++",
  },
  {
    title: "결제 만족도",
    sub: "A ++",
  },
  {
    title: "마케팅 효과",
    sub: `매출상승,
고객 유입 증가`,
  },
];
