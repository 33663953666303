import React, { SetStateAction, useCallback } from "react";
import AgreementCheck from "../AgreementCheck";

type Props = {
  text: string;
  termsCheck: number[];
  id: number;
  setTermsCheck: React.Dispatch<SetStateAction<number[]>>;
};

const AgreementCheckContainer = ({
  text,
  termsCheck,
  id,
  setTermsCheck,
}: Props) => {
  const TermsClicked = useCallback((id: number) => {
    setTermsCheck((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  }, [termsCheck]);
  return (
    <AgreementCheck
      termsCheck={termsCheck}
      text={text}
      id={id}
      TermsClicked={TermsClicked}
    />
  );
};

export default AgreementCheckContainer;
