import React from "react";
import "./css/index.css";
import { Link } from "react-router-dom";

const InquiryButton = () => {
  return (
    <Link className="inquiry-button" to={"/user/loading"}>
      사용 문의하기
    </Link>
  );
};

export default InquiryButton;
