import React from 'react'
import "./css/index.css";
type Props={
    text:string;
    color?:string
}
const Circle = ({text,color}:Props) => {
  return (
    <div className='circle-card' style={{backgroundColor:color}}>
        {text}
    </div>
  )
}

export default Circle