import React from "react";
import "./css/index.css";
import Bubble from "../Bubble/Bubble";
import { UserAgentType } from "../../interface/common/common.inferface";

type Props = {
  userAgent: UserAgentType;
};

const TrendMiddleBox = ({ userAgent }: Props) => {
  return (
    <div className="trend-middle-box">
      <Bubble
        title="모바일 마케팅"
        subTitle={
          userAgent !== "mb"
            ? `포스시스템과 마케팅 플랫폼의
연동으로 필터링 된 고객에게 타켓 마케팅`
            : `포스시스템과
마케팅 플랫폼의
연동으로 필터링 
된 고객에게
타켓 마케팅`
        }
        className="left-bubble"
      />
      <div className="skpos-logo-wrapper">
        <img
          className="top-line"
          src={`/assets/mainsection4/${userAgent}/top-line.png`}
          srcSet={`
                    /assets/mainsection4/${userAgent}/top-line@2x.png 2x,
                    /assets/mainsection4/${userAgent}/top-line@3x.png 3x,
                  `}
          alt="top-line"
        />
        <div className="skpos-logo">
          <img
            src={`/assets/mainsection4/${userAgent}/img-logo.png`}
            srcSet={`
                    /assets/mainsection4/${userAgent}/img-logo@2x.png 2x,
                    /assets/mainsection4/${userAgent}/img-logo@3x.png 3x,
                  `}
            alt="img-logo"
          />
          <img
            src={`/assets/mainsection4/${userAgent}/text-logo.png`}
            srcSet={`
                    /assets/mainsection4/${userAgent}/text-logo@2x.png 2x,
                    /assets/mainsection4/${userAgent}/text-logo@3x.png 3x,
                  `}
            alt="text-logo"
          />
        </div>
        <img
          src={`/assets/mainsection4/${userAgent}/bottom-line.png`}
          srcSet={`
                    /assets/mainsection4/${userAgent}/bottom-line@2x.png 2x,
                    /assets/mainsection4/${userAgent}/bottom-line@3x.png 3x,
                  `}
          alt="bottom-line"
          className="bottom-line"
        />
      </div>
      <Bubble
        title="통합관리"
        subTitle={
          userAgent !== "mb"
            ? `온,오프라인 통합 관리 서비스로 상품, 매출,
재고, 회원에 대한 체계적인 관리 지원`
            : `온,오프라인 통합 
관리 서비스로
상품, 매출, 재고, 
회원에 대한 체계
적인 관리 지원`
        }
        className="right-bubble"
      />
    </div>
  );
};

export default TrendMiddleBox;
