import "./css/index.css";
import { UserAgentType } from "../../interface/common/common.inferface";
import MainBannerContainer from "../../components/MainBanner/container/MainBannerContainer";
import MainSection3Container from "../../components/MainSection/MainSection3/container/MainSection3Container";
import MainSection4 from "../../components/MainSection/MainSection4/MainSection4";
import MainSection6 from "../../components/MainSection/MainSection6/MainSection6";
import MainSection7 from "../../components/MainSection/MainSection7/MainSection7";
import MainSection8 from "../../components/MainSection/MainSection8/MainSection8";
import MainSection9Container from "../../components/MainSection/MainSection9/container/MainSection9Container";
import MainSection10 from "../../components/MainSection/MainSection10/MainSection10";
import MainSection11 from "../../components/MainSection/MainSection11/MainSection11";
import MainSection12 from "../../components/MainSection/MainSection12/MainSection12";
import MainSection5Container from "../../components/MainSection/MainSection5/container/MainSection5Container";
import FooterContainer from "../../components/Footer/container/FooterContainer";
import MainSection2Container from "../../components/MainSection/MainSection2/container/MainSection2Container";
type Props = {
  userAgent: UserAgentType;
};

const Main = ({ userAgent }: Props) => {
  return (
    <main className="main-page">
      <MainBannerContainer userAgent={userAgent} />
      <MainSection2Container userAgent={userAgent} />
      <MainSection3Container userAgent={userAgent} />
      <MainSection4 userAgent={userAgent} />
      <MainSection5Container userAgent={userAgent} />
      <MainSection6 userAgent={userAgent} />
      <MainSection7 userAgent={userAgent} />
      <MainSection8 userAgent={userAgent} />
      <MainSection9Container userAgent={userAgent}/>
      <MainSection10 userAgent={userAgent}/>
      <MainSection11 userAgent={userAgent}/>
      <MainSection12 userAgent={userAgent}/>
      <FooterContainer/>
    </main>
  );
};

export default Main;