import React from "react";
import "./css/index.css";
import { UserAgentType } from "../../interface/common/common.inferface";
type Props = {
  userAgent: UserAgentType;
  img: string;
  sub: string;
  title: string;
  bgColor?: string;
};

const ContentCard = ({ userAgent, img, sub, title, bgColor }: Props) => {
  return (
    <div className="content-card">
      <div
        className="top-box"
        style={{
          backgroundColor: bgColor,
        }}
      >
        <p className="sub-text">{sub}</p>
        <p className="main-text">{title}</p>
      </div>
      <img
        src={`/assets/mainsection2/${userAgent}/${img}.png`}
        srcSet={`/assets/mainsection2/${userAgent}/${img}@2x.png 2x, /assets/mainsection2/${userAgent}/${img}@3x.png 3x`}
        alt={img}
      />
    </div>
  );
};

export default ContentCard;
