import React from "react";
import "./css/index.css";
import CustomInput from "../CustomInput/CustomInput";
import { Business, CounselInquiry } from "../../interface/inquiry/inquiry";

type Props = {
  data: CounselInquiry | Business;
  inputArr: {
    label: string;
    name: string;
    placeholder: string;
    maxLength: number;
    minLength: number;
    type: string;
    value: string;
    subLabel?:string;
  }[];
  ChangeUpdate: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};
const InquiryInput = ({ data, ChangeUpdate, inputArr }: Props) => {
  
  return (
    <div className="inquiry-wrapper">
      {inputArr.map(
        (
          {
            label,
            value,
            name,
            placeholder,
            maxLength,
            minLength,
            type,
            subLabel,
          },
          idx
        ) => (
          <CustomInput
            key={idx}
            label={label}
            value={value}
            name={name}
            placeholder={placeholder}
            maxLength={maxLength}
            minLength={minLength}
            type={type}
            subLabel={subLabel}
            ChangeUpdate={ChangeUpdate}
          />
        )
      )}
      <div className="textarea-box">
        <label className="label">문의내용</label>
        <textarea
          name="content"
          value={data.content}
          onChange={ChangeUpdate}
          id=""
          placeholder="내용을 입력해주세요"
          className="description"
        />
      </div>
    </div>
  );
};
export default InquiryInput;

