import React from 'react';
import "./css/index.css";

type Props = {
  title: string;
  subtitle: string;
}
const AlertTitle = ({ title, subtitle }: Props) => {
  return (
    <div className="title-wrapper">
      <p className="title">{title}</p>
      <p className="subtitle">{subtitle}</p>
    </div>
  );
}

export default AlertTitle