import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import MyInquiryContainer from "../page/MyInquiry/container/MyInquiryContainer";
import { UserAgentType } from "../interface/common/common.inferface";
import CounselInquiryContainer from "../page/InquiryForm/container/CounselInquiryContainer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducer";
import { PopupChange } from "../reducer/config";
import UserLoadingContainer from "../page/UserLoading/container/UserLoadingContainer";

type Props = {
  userAgent: UserAgentType;
};

const UserRoutes = ({ userAgent }: Props) => {
  const uid = useSelector<RootState, string | undefined>(
    (state) => state.auth.uid
  );
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(uid);
    if (!uid && uid !== undefined) {
      dispatch(
        PopupChange({
          active: true,
          type: "login",
        })
      );
    }
  }, [uid]);
  return (
    <Routes>
      <Route path="loading" element={<UserLoadingContainer uid={uid} />} />
      <Route
        path="/write"
        element={<CounselInquiryContainer userAgent={userAgent} />}
      />
      <Route
        path="/inquiry"
        element={<MyInquiryContainer userAgent={userAgent} />}
      />
    </Routes>
  );
};

export default UserRoutes;
