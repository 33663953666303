import "./css/index.css";
import { ReactComponent as Callsvg } from "./svg/call.svg";
import { ReactComponent as Emailsvg } from "./svg/email.svg";
import { ReactComponent as Addresssvg } from "./svg/address.svg";
const Footer = () => {
  return (
    <div className="footer-wrapper">
      <footer className="footer-container">
        <div className="container">
          <img
            src={"/assets/footerlogo2.png"}
            srcSet={`
              /assets/footerlogo2@2x.png 2x, /assets/footerlogo2@3x.png 3x
                `}
            className="footer-logo"
            alt=""
          />
          <div className="footer-text">
            (주)피플히어로 | 대표 남동현 | 사업자등록번호 493-81-03116
          </div>
          <div className="description-container">
            {arr.map(({ img, description }, idx) => (
              <div className={`contact `} key={idx}>
                {img}
                <p>{description}</p>
              </div>
            ))}
          </div>
          <a href="/privacy" target="blank" className="privacy ">
            개인정보 처리방침
          </a>
          <div className="reserved">©SKPOS. All rights reserved.</div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;

const arr = [
  {
    img: <Addresssvg />,
    description: "경북 경산시 삼풍로27 (삼풍동) 경북테크노파크 본관 4층 403호",
  },
  {
    img: <Callsvg />,
    description: "1533-4787",
  },
  {
    img: <Emailsvg />,
    description: "skpos0000@gmail.com",
  },
];
