import React from 'react';
import "./css/index.css";

type Props = {
  label: string;
  name: string;
  placeholder: string;
  subLabel?: string;
  maxLength: number;
  minLength: number;
  type: string;
  value: string;
  ChangeUpdate: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const CustomInput = ({
  label,
  name,
  placeholder,
  maxLength,
  minLength,
  subLabel,
  value,
  type,
  ChangeUpdate
}: Props) => {
  return (
    <div className='custom-input-wrapper'>
      <label className='label'>
        {label}
        {subLabel && <span className='sub-label'>{subLabel}</span>}
      </label>
      <input
        className='custom-input'
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        maxLength={maxLength}
        minLength={minLength}
        onChange={ChangeUpdate}
      />
    </div>
  )
}

export default CustomInput