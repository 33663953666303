import React from "react";
import "./css/index.css";
import { UserAgentType } from "../../../interface/common/common.inferface";
import useIntersectionObserver from "../../../hooks/UseIntersectionObserver";
type Props = {
  userAgent: UserAgentType;
};
const MainSection6 = ({ userAgent }: Props) => {
  const targetRef = useIntersectionObserver(0.3);
  const targetRef2 = useIntersectionObserver(0.4);
  const targetRef3 = useIntersectionObserver(0.5);
  return (
    <section className="main-section6 ">
      <img
        className="gradient hidden-section"
        ref={targetRef}
        src={`/assets/mainsection6/${userAgent}/gradient.png`}
        srcSet={`
                    /assets/mainsection6/${userAgent}/gradient@2x.png 2x,
                    /assets/mainsection6/${userAgent}/gradient@3x.png 3x,
                `}
        alt=""
      />
      <div className="text-wrapper hidden-section " ref={targetRef2}>
        <p className="main-text">{`언제 어디서든
실시간 매출 확인`}</p>
        <p className="sub-text">{`Android | IOS 마켓에서 무료 다운로드
사용료 0원`}</p>
      </div>
      <div className="img-wrapper hidden-section" ref={targetRef3}>
        <img
          src={`/assets/mainsection6/${userAgent}/phone-img.png`}
          srcSet={`/assets/mainsection6/${userAgent}/phone-img@2x.png 2x,
            /assets/mainsection6/${userAgent}/phone-img@3x.png 3x,
          `}
          alt={`phone-img`}
        />
      </div>
    </section>
  );
};

export default MainSection6;
